.form-hire {
    width: 100%;
}

.forms-container {
    margin: 2em;
    display: flex;
    justify-content: space-between;
}

.hire-employee-personal-data,
 .hire-employee-corporate-data {
    flex: 0 0 49%;
    display: flex;
    flex-direction: column;

    .header {
        margin: 0 0 1em 0;
    }

    .field-label {
        margin-top: .5em;
    }

    .required-field {
        color: $red;
    }

    .comment-container {
        flex: 1 0;
    }

    .comment-input {
        height: 100%;
        resize: vertical;
    }

    .phone-input::placeholder,
    .date-of-birth::placeholder {
        color: lighten($gray, 30%);
    }
    
    .warning-list {
        display: none;
        margin: .3em 0;
        border: 1px solid #fff692;
        background-color: tint(#fff692, 72%);
        border-radius: 5px;
        padding-bottom: 7px;
        padding-top: 7px;
    }

    .username-warning-list,
     .email-warning-list {
        display: none;
    }

    .employee-link {
        color: #428bca;

        &:hover {
            text-decoration: underline;
        }
    }
}

.buttons {
    border-top: 1px solid lighten($gray, 40%);
    margin-top: 2em;
    padding: 2em;
}