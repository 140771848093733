.img-avatar {
  display: inline-block;
  width: 2em;

  @include breakpoint(992px) {
    width: 3em;
    margin-right: .5em;
    border-radius: 50%;
  }
}

.nav-tabs {
  li.active:not(.user-block) {
    pointer-events: none;
  }
}
