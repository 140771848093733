.sow-skills {
  margin-top: 1em;
}

.sow__status {
  position: relative;
  top: auto;
  left: auto;

  &_1000 {
    background-color: lightgray;
  }

  &_300 {
    background-color: #78cd51;
  }

  &_200 {
    background-color: #78cd51;
  }

  &_100 {
    background-color: #fabb3d;
  }

  color: white;
  padding: 0 5px;
  white-space: nowrap;
}

.sow-body {
  padding: 1.5em 2em;
  overflow-x: hidden;
  background: white;

  li {
    list-style-type: none;
  }

  h2 {
    margin-top: 0;
    margin-right: 0.5em;
  }
}

.sow {
  h2 {
    margin: 0 0 10px;
    line-height: 1.1;
  }

  textarea {
    width: 100%;
  }

  &__section {
    margin-top: 10px;
    margin-bottom: 25px;

    &.collapse {
      white-space: nowrap;
      overflow: hidden;
      text-overflow:  ellipsis;
    }
  }

  &__more-toggle {
    h3 {
      display: inline;
    }

    .icon-angle-down {
      display: inline-block;
      transform: rotate(-180deg);
    }

    &.collapsed {
      .icon-angle-down {
        transform: rotate(0deg);
      }
    }
  }

  &__description {
    margin-top: 15px;
    white-space: pre-wrap;

    &.collapse {
      white-space: nowrap;
      overflow: hidden;
      text-overflow:  ellipsis;
    }
  }

  &__section:last-child {
    margin-bottom: 0;
  }
}

.sow-team {
  &__list {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
  }

  &__filters {
    margin: 0 1.5em;
  }

  &__item {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    margin-top: 1.5em;
    padding: 2em 15px 1em;

    @include with-layout(12 .25 split, true) {
      width: span(12);
      margin-left: gutter();
      margin-right: gutter();

      @include breakpoint($mobile) {
        width: span(6);
      }

      @include breakpoint($tablet) {
        width: span(4);
      }

      @include breakpoint($desktop-hd) {
        width: span(3);
      }

      @include breakpoint($desktop-uxga) {
        width: span(2);
      }

      .btn + .btn {
        margin: 0;
      }

      .btn-xs {
        padding: 0;
        margin: 0;
        font-size: 11pt;
      }
    }

    .badge {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .avatar {
      display: block;
      margin: 0 auto 6px;
    }

    .progress {
      margin-top: 6px;
      margin-bottom: 6px;

      .progress-bar {
        background-color: #5cb85c;

        &.novice {
          background-color: #0c880c;
        }

        &.senior {
          background-color: #9cf89c;
        }

        &:hover {
            background-color: #aaf;
            cursor: pointer;
        }
      }
    }

    .exp-bar {
        position: absolute;
        border-left: 1px solid #e4e6eb;
    }
  }

  &__role {
    font-size: 13px;
    color: $gray;
    margin-top: 6px;
  }
}

.sow-skill {
    .inherited {
      color: #3185a5;
    }

    margin-right: 0.5em;
}
