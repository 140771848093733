.google-visualization-tooltip-item {
  white-space: nowrap;

  .google-visualization-tooltip-label {
    font-family: Arial;
    font-size: 13px;
    color: rgb(0, 0, 0);
    opacity: 1;
    margin: 0;
    text-decoration: none;
  }

  .google-visualization-tooltip-value {
    @extend .google-visualization-tooltip-label;
    font-weight: bold;
  }
}