// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin stretch($top: 0, $right:0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin user-select($val: none) {
  -webkit-touch-callout: $val;
  -webkit-user-select: $val;
  -khtml-user-select: $val;
  -moz-user-select: $val;
  -ms-user-select: $val;
  user-select: $val;
}

@mixin box($display: inline-block, $position: relative, $valign: top) {
  display: $display;
  position: $position;
  vertical-align: $valign;
}

@mixin rect($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($side) {
  @include rect($side, $side);
}

@mixin circle($radius) {
  @include square($radius);
  border-radius: $radius;
  overflow: hidden;
}

@mixin triangle($color: white, $width: 5px, $height: 5px, $direction: top) {
  border-style: solid;
  border-width: $height $width;
  border-color: transparent;

  border-#{$direction}-width: 0;

  @if $direction == right {
    border-left-color: $color;
  } @else if $direction == bottom {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

@mixin pseudo($display: inline-block, $position: relative) {
  content: "";
  @include box($display, $position, middle);
}

@mixin user-select($value: none) {
  -webkit-touch-callout: $value;
    -webkit-user-select: $value;
     -khtml-user-select: $value;
       -moz-user-select: $value;
        -ms-user-select: $value;
            user-select: $value;
}

@mixin link($color: $link-color, $hover-color: $hover-color, $type: original, $opacity: 1) {
  transition: color .2s, border-color .2s, opacity .2s;

  color: $color;
  text-decoration: none;
  @if $type == pseudo {
    border-bottom: 1px dotted tint($color, 30%);
    border-bottom: 1px dotted rgba($color, .3);
  } @else {
    border-bottom: 1px solid tint($color, 30%);
    border-bottom: 1px solid rgba($color, .3);
  }

  &:hover {
    color: $hover-color;
    @if $type == pseudo {
      border-bottom: 1px dotted tint($hover-color, 30%);
      border-bottom: 1px dotted rgba($hover-color, .3);
    } @else {
      border-bottom: 1px solid tint($hover-color, 30%);
      border-bottom: 1px solid rgba($hover-color, .3);
    }

    @if $opacity < 1 {
      opacity: $opacity;
    }
  }
}

@mixin placeholder-color($color: '#ccc') {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin animation($args) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -ms-animation: $args;
  -o-animation: $args;
  animation: $args;
}

@mixin animation-name($args...) {
  -webkit-animation-name: $args;
  -moz-animation-name: $args;
  -ms-animation-name: $args;
  -o-animation-name: $args;
  animation-name: $args;
}

@mixin translate($x: 0, $y: 0) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin dot-after {
  margin-right: 0;

  &:after {
      content: '\00B7';
      margin: 0 .75em;
    }
}

@mixin shadow-box {
  box-shadow: 0 2px 1px 1px rgba(0,0,0,0.05);
}

@mixin separator($width: 100%, $margin: 2em, $color: $smoke, $center: true) {
  @include pseudo(block);
  @include rect($width, 1px);

  background: $color;
  @if $center == true {
    margin: $margin auto;
  } @else {
    margin: $margin 0;
  }
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

//  Function to create an optimized svg url
@function svg-url($svg) {
  //  Add missing namespace
  @if not str-index($svg, xmlns) {
    $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
  }

  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml,#{$encoded}");
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @return if($index,
    str-slice($string, 1, $index - 1) + $replace +
    str-replace(str-slice($string, $index +
    str-length($search)), $search, $replace),
    $string);
}

@mixin background-svg($svg){
  background-image: svg-url($svg);
}