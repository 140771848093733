.skills {
  display: block;
  text-align: right;

  .planned {
    border: 1px solid black;
  }

  &-item {
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 4px;
    padding: .25em 1em 0;
    border-left: 3px solid;

    &:hover {
      .icon-pencil,
      .remove-cross {
        display: block;
      }

      .skills-item-highlight {
        opacity: 1;
        visibility: visible;
        width: 100%;
      }
    }

    .icon-pencil {
      display: none;
      position: absolute;
      top: .65em;
      right: 2em;
      cursor: pointer;
    }

    .remove-cross {
      display: none;
      position: absolute;
      top: .5em;
      right: .5em;
      cursor: pointer;
    }

    &-title {
      font-size: 1em;
      margin: .2em 0 .2em;
    }

    &-rating {
      height: 20px;
      display: inline-block;
      white-space: nowrap;
      vertical-align: top;

      .icon-star {
        margin: 0 2px;
        cursor: pointer;

        &:before {
          font-size: 16px;
        }

        &.owned-point {
          &:before {
            color: $blue;
          }

          &.approved:before {
            color: gold;
          }
        }
      }
    }

    &-highlight {
      transition: all .3s;
      opacity: 0;

      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
    }

    &__rating-cell, &__attitude-cell {
      vertical-align: middle !important;
    }
    &__attitude-cell {
      .select2-container {
        min-width: 11em;
      }
    }
  }

  .attitude {
      position: relative;
      margin-right: .5em;
  }

  .controls {
      text-align: left;
  }

  &__empty {
    text-align: center;
    padding: 2em;
  }

  &_path {
    font-size: 13px;
    font-weight: 300;
    color: gray;
  }
}

.personal-skills {
  transition: opacity $transfast;
  opacity: 1;

  .skills_loading & {
    opacity: .6;
  }
}

#skillsGroups {
  .attitude {
    position: relative;
    display: inline-block;
  }

  .controls {
    text-align: left;
  }

  .skills-group {
    @include clearfix;

    display: block;
    margin-bottom: 2em;

    &-title {
      font-size: 1.25em;
    }
  }

  .btn-minimize-group {
    cursor: pointer;
  }

  .skills-group-items {
    @include clearfix;
    padding-top: 1.5em;
  }
}

.skill {
  border-radius: 2px;

  display: block;
  float: left;
  position: relative;
  width: 46%;
  height: 150px;
  margin: 0 2% 10px;
  padding: 0;
  text-align: center;
  border: 1px rgba(0, 0, 0, 0.1) solid;

  &:hover {
    .icon-remove {
      display: block;
    }
  }

  @include breakpoint($tablet) {
    width: 14em;
    height: 180px;
    margin: 0 10px 10px 0;
  }

  .icon-remove {
    @include square(24px);
    font-size: 28px;
    font-weight: 300;
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    line-height: 24px;
    padding: 0;
    text-align: center;
  }

  .skill-approve-button {
    @include square(24px);
    position: absolute;
    bottom: 2px;
    right: 5px;
    padding: 0;
    text-align: center;
    z-index: 10;
  }

  .skill-approvement {
    font-size: small;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 5px;
    text-overflow: ellipsis;
  }

  &-title {
    font-size: 14px;
    font-weight: 600;
    margin: 10px;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoint($tablet) {
      font-size: 15px;
      margin: 1em;
    }
  }

  &-rating {
    font-size: 12px;
    display: block;
    height: 30px;
    padding: 8px 1em;
    white-space: nowrap;
    vertical-align: top;
    background: #f0f0f0;
    position: absolute;
    bottom: 0;
    width: 100%;

    .icon-star {
      margin: 0 2px;
      cursor: pointer;

      &:before {
        font-size: 16px;
        color: #ccc;
      }

      &.owned-point {
        &:before {
          color: $blue;
        }

        &.approved:before {
          color: gold;
        }
      }
    }
  }

  &-highlight {
    transition: all .3s;
    opacity: 0;
    @include rect(0, 100%);

    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-attitudes {
    position: relative;
    width: 64px;
    margin: 0 auto 1em;

    .btn {
      background: transparent;
    }

    .dropdown-toggle {
      @include rect(64px, 18px);

      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0;

      .caret {
        margin-top: -5px;
      }

      &:active {
        box-shadow: none;
      }
    }

    &.open {
      .current-attitude:after {
        box-shadow: 0 2px 4px rgba(black, .15) inset;
      }
    }

    .dropdown-menu {
      left: 50%;
      width: 12em;
      margin: 6px 0 0 -6em;

      &:before,
      &:after {
        @include pseudo;
        @include square(0);

        position: absolute;
        left: 50%;
        top: 0;
        margin: -6px 0 0 -6px;
        border-width: 0 6px 6px !important;
        border-style: none solid solid !important;
        border-color: transparent transparent #c7cbd5 !important;
      }

      &:after {
        margin-top: -5px;
        border-bottom-color: white !important;
      }

      .attitude {
        margin: 5px 8px 5px 0;
        vertical-align: middle;
      }
    }
  }

  .current-attitude {
    @include box;
    @include circle(64px);
    overflow: hidden;
    border: 1px solid $smoke;

    &:after {
      @include pseudo;
      @include rect(100%, 18px);
      position: absolute;
      left: 0;
      bottom: 0;
      background: $smoke;
    }

    .attitude {
      margin: 6px 0 0;
    }
  }

  &-demand {
    @include box($valign: middle);

    font-size: 14px;
    margin-left: 2em;

    &__level {
      @include box($valign: middle);

      width: 16px;
      margin-right: .75em;
    }

    &__item {
      @include rect(16px, 5px);

      display: block;
      position: relative;
      margin-bottom: 2px;
      background: #d6d6d6;

      &:nth-child(3) {
        background: $blue;
      }
    }

    &_level {
      &_2 {
        .skill-demand__item {
          &:nth-child(2) {
            background: tint($green, 25);
          }
        }
      }

      &_3 {
        .skill-demand__item {
          &:first-child {
            background: $orange;
          }

          &:nth-child(2) {
            background: tint($green, 25);
          }
        }
      }
    }
  }
} // .skill

.switch-toggle label {
  font-weight: normal;
  cursor: pointer;
}

.icon-remove {
  font-size: 1.2em;
  color: rgba(0,0,0,0.5);
  cursor: pointer;

  &:hover {
    color: black;
  }
}

.skill-filter {
  &__search {
    margin: 0 5px;

    input {
      height: 44px;

      &,
      &:focus {
        border-color: transparent;
      }
    }
  }

  &__selected-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 40px 8px 16px;
    border-top: 1px solid rgba(black, .1);

  }

  &__stars {
    position: relative;

    select {
      width: auto;
      height: 28px;
      line-height: 12px;
      margin: 90;
      background: none;
      position: relative;
      z-index: 1;
    }

    &-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      color: $blue;
    }
  }

  &__skill-name {
    flex: auto;
    margin-right: 16px;
  }

  &__approved {
    label {
      margin-bottom: 0;
    }
  }

  &__remove {
    font-size: 24px;
    font-weight: 300;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    transform: translateY(-50%);
    line-height: 18px;
    color: $gray;
    cursor: pointer;
  }
}
