html {
    height: 100%;
}

.inline-block {
    display: inline-block;
    vertical-align: middle;
}

input[placeholder], [placeholder], *[placeholder] {
    color: #424854 !important;
}

h1 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.3;
}

h2 {
    font-size: 20px;
    line-height: normal;
    font-weight: normal;
    margin-top: 10px;

    &:not(:first-child) {
        margin-top: 2em;
    }
}

img {
    max-width: 100%;
}

ol li {
    margin-bottom: .5em;
}

p {
    margin-bottom: 1.5em;
}

.inline {
    display: inline-block;
}

.center {
    float: none;
    margin: 0 auto;
}

.icon-required {
    color: rgb(208, 68, 55);
    vertical-align: super;
    font-size: 0.5em;
}
