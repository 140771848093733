.chosen {
  &-container {
    font-size: inherit;

    &-single {
      .chosen-single {
        border-radius: 3px;
        box-shadow: none;

        height: auto;
        padding: .5rem .7rem;
        background: white;
        border-color: #c7cbd5;

        .section__header & {
          border: none;
        }

        div {
          b {
            @include triangle($gray, 5px, 5px, bottom);

            position: absolute;
            top: 50%;
            right: 8px;
            width: 0;
            height: 0;
            margin: -3px 0 0;
          }
        }
      }

      .chosen-search {
        padding: 5px;
        background: $smoke;

        &:after {
          @include pseudo;
          @include square(18px);

          position: absolute;
          top: 50%;
          right: 10px;
          margin: -9px 0 0;
          background: url('../../vendor/css/chosen-sprite.png') no-repeat 100% -22px;
        }

        input[type=text] {
          padding: 7px 20px 7px 7px;
          color: $font-color;
          background: white;
          border: none;
        }
      }
    }

    &-multi {

      .chosen-choices {
        box-shadow: none;

        border: 1px solid #c7cbd5;
        background: transparent;

        li.search-field input[type=text] {
          font-weight: 300;
          font-style: italic;
          height: auto;
          padding: 5px;
          margin: 4px;
          color: white;
        }

        li.search-choice {
          box-shadow: none;
          border-radius: 3px;

          line-height: inherit;
          margin: 4px 0 0 4px;
          padding: .125em 24px .125em .5em;
          background: $smoke;
          border: none;

          .search-choice-close {
            top: 50%;
            right: 5px;
            margin: -5px 0 0 0;
          }
        }
      }
    }

    &-active {
      .chosen-choices {
        padding: 0;

        li.search-field input[type=text] {
          font-weight: normal;
          font-style: normal;
          color: white !important;
        }
      }
      &.chosen-with-drop {
        .chosen-single {
          box-shadow: none;
          border-color: #c7cbd5;
          background: white;
        }
      }
    }

    .chosen-results {
      margin: 0;
      padding: 0;
      color: $font-color;

      li {
        padding: 7px 10px;
        line-height: inherit;

        &.highlighted {
          background: $blue;
        }

        &.no-results {
          background: white;
        }

        em {
          text-decoration: none;
          color: $font-color;
          background: white;
        }
      }
    }

    .chosen-drop {
      border-radius: 0 0 3px 3px;
      box-shadow: 0 1px 0 1px #e4e6eb;

      background: white;
      border: none;

      .result-selected {
        display: none;
      }
    }
  }
}
