.vcentered {
    vertical-align: middle;
}

.timeoffs-calendar-table {
    table-layout: fixed;
    border-collapse: collapse;

    .even, .odd {
        height: 8em !important;
    }

    td {
        vertical-align: middle;
        white-space: normal !important;
    }

    td.week {
        padding: 0 !important;
        border: 1px dotted #d4d4d4;
    }

    td.empinfo {
        width: 80px !important;
    }

    .intersecting-leave-wrapper {
        overflow: hidden;
        height: 3em;
        padding: 0;
        margin: 0;
    }

    .leave-wrapper, .skills-wrapper {
        overflow: hidden;
        max-height: 7.5em !important;
        padding: 0;
    }

    .leave-wrapper {
        height: 7.5em !important;
    }

    .skills-wrapper {
        max-width: 70px;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
    }

    .leave-1, .leave-2, .leave-3, .leave-4, .leave-5, .leave-6, .leave-7 {
        display: inline-block;
        overflow: hidden;
        margin: 0;
        padding: 5px;
        height: 100%;
        text-overflow: ellipsis;
    }

    .leave-7 {
        width: 100%;
    }

    .leave-6 {
        width: 84%;
    }

    .leave-5 {
        width: 70%;
    }

    .leave-4 {
        width: 56%;
    }

    .leave-3 {
        width: 41%;
    }

    .leave-2 {
        width: 27%;
    }

    .leave-1 {
        width: 14%;
    }

    .leave-0 {
        display: none;
    }

    .vacation-state-200 {
        background-color: #fdd;
    }

    .vacation-state-300, .vacation-state-75 {
        background-color: #dfd;
    }

    .vacation-state-9000 {
        background-color: #ddf;
    }

    .vacation-state-draft {
        background-color: #428bca;
        color: white;
    }
}

.timeoffs-calendar-toolbar {
    background: #f5f5f5;
    padding: 10px 20px;
    border-bottom: 1px solid #d4d4d4;
    display: flex;
    justify-content: space-between;

    .dataTables_filter {
        width: min-content;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: .25em 0 .25em 0;

        label {
            margin: .25em 0 .25em .5em !important;

            .select2-selection {
                width: 200px;
            }
        }
    }

    .toolbar-controls {
        display: flex;
        flex-direction: column;

        .calendar-data-reload-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-content: flex-start;
            margin: .25em 0 .25em 0;

            label {
                display: flex;
                align-items: center;
                width: fit-content;

                input {
                    margin-left: .25em;
                }
            }

            label, button, a {
                margin: .25em 0 .25em .5em;
            }
        }

        .legend {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin: .25em 0 .25em 0;

            .legend-item {
                margin: .25em 0 .25em .5em;
                display: flex;
                align-items: center;

                .rect {
                    width: 2em;
                    height: 1em;
                    display: inline-block;
                    margin: 0 0.5em;
                }
            }
        }
    }
}