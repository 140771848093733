// Deprecated. Use .avatar instead

.userpic {
	border-radius: 100%;
}

.userpic-small {
	width: 32px;
	height: 32px;
}
