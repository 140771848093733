.radio-group {
  &_type {
    &_button {
      position: relative;
      display: inline-flex;
      vertical-align: middle;
      align-items: baseline;

      .btn {
        &:before {
          right: 0;
        }
      }

      > :last-child {
        .btn {
          &:before {
            right: 1px;
          }
        }
      }
    }
  }
}