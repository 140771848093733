.tab-pane {
	padding: 1em 0;

  .box-content & {
    padding: 0;
  }
}

.nav-tabs {
  > li {
    float: none;
    display: inline-block;
  }

  .box-header & {
    padding: 20px 15px 0;
    background: white;
    border-bottom: 1px solid rgba(black, .1);

    @include breakpoint($tablet) {
      padding: 20px 25px 0;
    }


    & > li {
      margin-bottom: -1px;
      border-bottom: 2px solid transparent;

      &:not(:last-child) {
        margin-right: 25px;
      }

      & > a {
        margin-right: 0;
        padding: 1em 0;
        color: $gray;
        border-radius: 0;

        &,
        &:hover,
        &:focus {
          border: none;
          background: transparent;
        }

        &:hover {
          color: $font-color;
        }
      }

      &.active {
        border-bottom-color: $blue;

        & > a {
          &,
          &:hover {
            color: $font-color;
            background: white;
          }
        }
      }
    }
  }
}
