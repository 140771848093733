.employees-list {
  @include clearfix;
  padding: 10px;

  @include breakpoint($tablet) {
    padding: 20px 24px;
  }

  &_view {
    &_grid {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(1, 1fr);

      @include breakpoint($mobile) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint($tablet) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include breakpoint($desktop-hd) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include breakpoint($desktop-uxga) {
        grid-template-columns: repeat(5, 1fr);
      }

      @include breakpoint($desktop-fhd) {
        grid-template-columns: repeat(6, 1fr);
      }

      @include breakpoint($desktop-qhd) {
        grid-template-columns: repeat(8, 1fr);
      }
    }

    &_list {
      flex: auto;
    }
  }

  .attitude {
    margin-left: 4px;
    display: inline-block;
  }
}

.dismissal-sign {
  position: absolute;
  top: 0;
  right: 7px;
  color: lightgray;
}

.employees-item {
  display: grid;
  grid-template-rows: 14px minmax(45px, auto) minmax(14px, auto);
  grid-template-columns: auto 3fr 3fr 2fr auto;

  background: white;
  border-radius: 3px;
  position: relative;

  .profile-info {
    align-items: center;
    flex: auto;
  }

  .employee-name {
    .link {
      @include link($font-color, $font-color);
    }
  }

  .employees-list_view_grid & {
    @include card;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    padding: 25px 15px 15px;

    &_matched {
      &_no {
        display: none;
      }
    }

    .avatar {
      @include square(80px);
      display: block;
      margin: 0 auto 15px;
    }

    .employee-name {
      font-size: 16px;
      display: inline-block;
      margin: 0 0 5px;
    }

    .skills-item-rating {
      display: inline-block;
      font-size: 14px;
    }

    .star {
      cursor: default;
    }

    &__skills {
      width: 100%;
      margin: 1em auto 0;

      &:empty {
        margin-top: 0;
      }
    }

    &__skill {
      display: inline-flex;
      width: 100%;
      height: 2em;
      text-align: left;

      .skill-name {
        flex: auto;
      }

      .skills-level {
        flex: 0 0 auto;
      }
    }

    &__achievements {
      margin: 5px 0 0;
      display: grid;
      grid-row: 2 / 2;
      grid-column: 3 / 3;

      .achievements {
        @extend .achievements_layout_compact;
      }
    }

    &__buttons {
      position: absolute;
      top: 0;
      right: 0;
    }

    .profile-info {
      display: flex;
      flex-direction: column;
    }
  }

  .employees-list_view_list & {
    display: grid;
    padding-left: 1em;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(black, .1);

    .employee-badge {
      position: absolute;
      top: auto;
      bottom: 3%;
      left: 70px;

      @include breakpoint($desktop) {
        bottom: 15%;
      }

    }

    .profile-info {
      display: grid;
      grid-column: 2 / 4;

      grid-row: 2 / 2;
      grid-template: repeat(2, auto) / 1fr;
      align-self: center;

      @include breakpoint($mobile) {
        display: grid;
        grid-column: 2 / 3;
        grid-row: 2 / 2;
      }

      @include breakpoint($desktop) {
        grid-column: 2 / 2;
        grid-template: 1fr / repeat(2, 1fr);
      }

    }

    .employee-name {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
      margin: 0 0 5px;

      @include breakpoint($desktop) {
        grid-row: 1 / -1;
        margin: 0;
      }

    }

    .employee-contact-info {
      display: grid;
      grid-column: 1 / 1;
      grid-row: 2 / 2;
      margin-bottom: 5px;

      @include breakpoint($desktop) {
        grid-column: 2 / -1;
        grid-row: 1 / -1;
        margin-bottom: 0;
      }

      a {
        color: $gray;
        cursor: pointer;
      }

    }

    .employee-phone-icon {
      grid-column: -1 / -1;
      grid-row: 1 / -1;
      margin-right: 14px;
      background-color: #27ae60;
      font-size: 18px;
      color: #fff;
      padding: 6px 8px;
      border-radius: 50%;
      align-self: center;
      justify-self: end;

      @include breakpoint($desktop-hd) {
        display: none;
      }
      :hover {
        cursor: pointer;
      }
    }

  }

  .employee-job-title {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    margin-bottom: 5px;

    @include breakpoint($desktop) {
      grid-column: 2 / 2;
      grid-row: 1 / -1;
      margin-bottom: 0;
    }
  }

  .avatar {
    float: left;
    margin-right: 14px;
  }

  .employee-avatar {
    display: grid;
    grid-column: 1 / 1;
    grid-row: 1 / span 2;
    align-self: center;

    @include breakpoint($mobile) {
      grid-row: 1 / -1;
    }
  }

  &__skills {
    grid-column: 1 / -1;
    grid-row: 3 / 3;
    align-self: center;
    margin-bottom: 25px;

    @include breakpoint($mobile) {
      grid-column: 3 / -1;
      grid-row: 2 / 2;
      align-self: center;
      margin-bottom: 0;
    }

    @include breakpoint($desktop) {
      grid-row: 2 / 2;
      grid-column: 3 / 3;
      justify-self: center;
      align-self: center;
      align-items: center;
      margin-bottom: 0;
    }

    &:empty {
      display: none;
    }

    @include breakpoint($desktop-hd) {
      margin-top: 0;
    }

    .skills-name-data {
      max-width: 100px;
      width: 100%;
      padding: .35em 1em 0 0;
      line-height: 1.3;
      text-align: left;
      vertical-align: top;

      .skill-name {
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .skills-level-data {
      width: 1%;
      text-align: right;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  &__skill {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;

    .skill-name {
      grid-row: 1 / 1;
      grid-column: 2 / 2;

      align-self: center;
    }

    .skills-level {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
      margin-right: 1em;
      align-self: center;
    }
  }

  &__achievements {
    display: none;
    margin-bottom: 25px;
    align-items: start;
    align-self: center;

    @include breakpoint($desktop) {
      display: grid;
      grid-row: 2 / 2;
      grid-column: 4 / 4;
      align-items: center;
      margin-bottom: 0;
      margin-left: 1em;
    }

    &:empty {
      display: none;
    }
  }

  &__buttons {
    z-index: 1;
    grid-column: -1 / -1;
    grid-row: 1 / -1;
    align-self: center;

    .dropdown-menu {
      right: 30px;
      top: 0;
    }
  }

}

.employees-hire {
  @include card;
  font-size: 13px;
  display: flex;
  background: white;
  border-radius: 3px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    text-decoration: none;
  }

  .employees-list_view_grid & {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    border: 1px dashed rgba(black, 0.15)
  }

  .employees-list_view_list & {
    justify-content: center;
    margin-bottom: 20px;
    padding: 15px 20px;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(black, .1);
  }

  &__avatar {
    display: block;
    position: relative;
    color: $light-gray;
    text-align: center;

    .employees-list_view_grid & {
      @include square(80px);
      font-size: 40px;
      margin-top: 14px;
      margin-bottom: 25px;
      line-height: 75px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .employees-list_view_list & {
      font-size: 26px;
      margin: 0 20px 0 0;
      padding-left: 2px;
      line-height: 36px;
    }
  }

  &__plus {
    font-family: monospace;
    font-style: normal;
    line-height: 1;
  }
}

.optionGroup {
  font-weight: bold;
  font-style: italic;
}
