@import "add_vacation";
@import "calendar";

//What does other rows do in list.scss, move to another file --akostylev
$element-focus: black;
$button-bg: #f5f5f5;
$button-bg-enabled-hover: $blue;
$button-bg-enabled: $light-blue;
$button-bg-disabled-hover: $light-gray;
$button-text-enabled: white;

.clickable {
    cursor: pointer;
}

.vacation-table {
  width: 100%;
}

#vacation_list_wrapper {
  flex: auto;
  overflow-x: hidden;
}

.buttons_wrapper {
    margin-bottom: 15px;
}

.vacation-table-wrapper {
  font-size: 13px;
  clear: both;
  display: flex;
  flex: auto;

  .toolbar {
    display: flex;
    background: #f5f5f5;
    vertical-align: bottom;

    .grid-buttons {
        width: 100%;

        .btn-group {
            width: 100%;
        }
    }

    .left-side a {
        position: absolute;
        bottom: 0.6em;
        margin-left: 1em;
        font-size: 110%;
        font-weight: bold;
    }

    .right-side {
        float: right;
    }
  }

  .dataTables_filter {
    float: none;
    flex: auto;
    text-align: left;

    label {
      float: none;
      margin: 0;
      margin-right: 5px;
      min-width: 200px;
    }

    input {
      margin: 0;
    }
  }

  .loader {
    display: none;
  }

  &.loading {
    .loader {
      display: block;
    }
  }
}

.user-vacations {
  display: block;
  margin: 0 -25px;
}

.vacation-grid {
  flex: auto;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  background: white;

  .loading & {
    display: none;
  }
}

.vacation-row {
  > td:first-child {
    border-left: 10px solid transparent;
  }

  &_status {
    &_pending {
      > td:first-child {
        border-color: $warning-color;
      }
    }

    &_approved,
    &_order-is-ready {
      > td:first-child {
        border-color: $success-color;
      }
    }

    &_rejected {
      > td:first-child {
        border-color: $red;
      }
    }

    &_cancelled,
    &_cancelled-by-author {
      > td:first-child {
        border-color: $light-gray;
      }
    }
  }
}

.vacation-filter {
  &__item {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .filter_name {
    max-width: 500px;

    & input {
      margin: .5em 0;
    }
  }

  .daterange_filter {
    width: 15em;
    margin: 0 .25em;
  }
}
.vacation_actions {
  margin-top: 5px;

  .btn + .btn {
    margin-left: 0.1em;
  }
}

.empty_table_message {
    margin: 1.5em 2em;
}

.filter_period_days_ahead {
    margin: 0 0.2em;
    width: 5em;
}

.vacation-view-button__link {
    font-size: 1.2em;
    cursor: pointer;
    color: $gray;

    &:hover {
        text-decoration: none;
        color: darken($gray, 15%);
    }
}

.approver_status {
  &-approved {
    color: #bbb;

    .state_icon:before {
        @extend .fa;
        color: $green;
        content: "\f00c";
    }
  }

  &-rejected {
    color: #bbb;

    .state_icon:before {
        @extend .fa;
        color: $red;
        content: "\f05e";
    }
  }
}

.vacation_approver {
    display: inline;
}
