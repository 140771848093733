.noty_theme__light {
  &.noty_bar {
    font-weight: 300;
    margin: 4px 0;
    overflow: hidden;
    border-radius: 4px;
    position: relative;

    .noty_body {
      padding: 12px 32px 12px 16px;
    }

    .noty_buttons {
      padding: 8px 16px;
      text-align: right;
      border-top: 1px solid rgba(black, 0.15);
    }

    .btn {
      font-weight: 300;
    }
  }

  &.noty_type__alert,
  &.noty_type__notification {
    background-color: #fff;
    border: 1px solid #dedede;
    color: #444;
  }

  &.noty_type__warning {
    background-color: #fcad48;
    background: linear-gradient(to right, #fcad48, #FFBC00);
    border: 1px solid #fcad48;
    color: white;
  }

  &.noty_type__error {
    background-color: $red;
    background: linear-gradient(to right, $red, $orange);
    border: 1px solid $red;
    color: white;
  }

  &.noty_type__info,
  &.noty_type__information {
    background-color: $blue;
    background: linear-gradient(to right, $blue, $light-blue);
    border: 1px solid $blue;
    color: white;

    .btn-primary {
      background: white;
      color: $blue;

      &:active:focus {
        background: white;
        color: $blue;
      }
    }

    .btn-secondary {
      background: transparent;
      color: white;

      &:active:focus {
        background: rgba(black, 0.05);
      }
    }
  }

  &.noty_type__success {
    background-color: #06CC81;
    background: linear-gradient(to right, #06CC81, #2AD2AE);
    border: 1px solid #06cc81;
    color: white;
  }

  .noty_close_button {
    font-weight: 300;
    font-size: 16px;
    top: 8px;
    right: 8px;

    &,
    &:hover {
      background: transparent;
    }
  }
}
