.achievements {
  &__header {
    @include clearfix();
  }

  &__container {
    display: flex;
    flex-flow: row wrap;
  }

  &_layout {
    &_compact {
      .achievement {
        &:not(:only-child) {
          margin: 0 -4px;
        }
      }

      .achievement__counter {
        opacity: 0;
      }

      &:hover,
      &.active {
        .achievement {
          margin: 0;
          transition: all .255s;
        }

        .achievement__counter {
          opacity: 1;
          transition: all .255s .255s;
        }
      }
    }
  }
}

.employee_achievements_window {
  &__list {
    text-align: center;

    &__table {
      text-align: left;
      word-break: break-word;

      & th:first-child {
        width: 33%;
      }

      td {
        min-width: 90px;
      }

    }
  }
  &__remove_section {
    text-align: right;
    span, a {
      cursor: pointer;
      color: lighten($gray, 20%);
      &:hover {
        color: $gray;
        text-decoration: none;
      }
    }

    .hidden {
        display: none;
    }
  }
}

.achievement {
  position: relative;
  width: 60px;
  height: 60px;
  float: left;
  margin: 2px;
  cursor: pointer;

  &_size {
    &_s {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      float: none;
      width: auto;
      height: auto;
      margin: 0;
      text-align: center;

      .achievement__icon {
        flex: 0 0 36px;
        width: 36px;
        height: 36px;
        padding: 0;
        border: 2px solid $white;
        transition: none;

        &:hover {
          box-shadow: none;
        }
      }

      .achievement__counter {
        position: relative;
        top: 3px;
        left: auto;
        font-size: 10px;
        line-height: 1;
        box-shadow: none;
        border: none;
      }
    }
  }

  &__details {
    display: none;
  }

  &.active {
    cursor: default;
    pointer-events: none;

    .achievement__icon {
      border: 5px solid rgba($blue, 0.8);
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    border: 5px solid $white;
    border-radius: 50%;
    overflow: hidden;
    transition: all .55s;

    &:hover {
      box-shadow: 0 0 5px rgba(black, 0.25);
    }
  }

  &__counter {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 20px;
    width: 20px;
    padding: 2px 0 0 0;
    background-color: $white;
    border-radius: 50%;
    color: $mine-shaft;
    font-size: 12px;
    text-align: center;
    transition: all .255s .55s;
    box-shadow: 0 0 5px rgba(black, 0.25);
  }

  &__add {
    width: 100%;
    height: 100%;
    background-color: $smoke;
    border-radius: 50%;
    border: 1px dashed $dusty-gray;
    text-align: center;
  }

  &__add-icon {
    font-size: 30px;
    color: $dusty-gray;
  }

  &__toggle {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: $smoke;
    border-radius: 50%;
    border: 1px dashed $dusty-gray;
    text-align: center;
    text-decoration: none;

    &:active,
    &:hover {
      text-decoration: none;

      .achievement__toggle-text {
        color: $dusty-gray;
      }
    }
  }

  &__toggle-text {
    font-size: 14px;
    line-height: 48px;
    color: $dusty-gray;
  }
}

.achievements-available {
  &__expand-wrap {
    position: relative;
    float: left;

    &.collapsing {
      position: absolute;
      opacity: 0;
    }
  }
}

.add_achievement_window {
  &__achComment {
       max-width: 100%;
       max-height: 170px; /*this height is enough to display maxlength of textarea*/
   }
}

.js-achievement-required-field-error {
  font-size: 12px;
  color: red;
  text-align: center;
}
