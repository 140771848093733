.search {
  position: relative;
  width: 100%;

  &:before {
    @extend .fa;
    @extend .fa-search;

    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: $gray;
  }

  &__input {
    padding-left: 30px;
  }
}