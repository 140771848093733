.toggle {
  font-weight: inherit;
  display: inline-flex;
  align-items: center;

  [type="checkbox"],
  [type="radio"] {
    display: none;
  }

  &__icon {
    @extend .fa;
    font-size: 18px;
    margin-right: 8px;
    color: $gray;

    &:before {
      content: $fa-var-toggle-off;
    }

    [type="checkbox"]:checked + &,
    [type="radio"]:checked + & {
      color: $success-color;

      &:before {
        content: $fa-var-toggle-on;
      }
    }
  }
}