.pagination {
  @include card;

  display: inline-flex;
  justify-content: center;
  margin: 20px auto;
  padding: 8px;

  &:hover {
    box-shadow: none;
  }

  > li > a,
  > li > span {
    border: none;
    border-radius: 16px;
    margin: 0 2px;
  }

  > .active > a,
  > .active > span,
  > .active > a:hover,
  > .active > span:hover,
  > .active > a:focus,
  > .active > span:focus {
    background-color: $blue-smoke;
    color: $link-color;
  }

  > li:first-child > a,
  > li:first-child > span {
    border-radius: 16px;
  }

  > li:last-child > a,
  > li:last-child > span {
    border-radius: 16px;
  }
}