.org-structure-data {
  a {
    color: #428bca;
  }
}

.corporate-profile-tab {
  .btn-toggle-edit-form {
    margin-bottom: 10px;
    background-color: #e8e8e8;
  }

  .edit-form-wrapper {
    padding: 1.5em 2em;
    margin: 0 -2em 1.5em;
    background: #ececec;
  }

  .edit-formset {
    .edit-form {
      padding: .5em 2em;
      margin: .1em -1em;
      background: #e3e3e3;
    }
  }

  .form-field-wrapper {
    margin: 1em 0;

    label {
      margin: 0;
    }
  }

  .errorlist {
    margin: .3em 0;
    border: 1px solid $red;
    background-color: tint($red, 72%);
    border-radius: 5px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
