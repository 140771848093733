@mixin card {
  background: white;
  border-radius: 3px;
  border: 1px rgba(black, 0.1) solid;
  transition: all .255s;

  &:hover {
    box-shadow: 0 15px 40px -5px rgba(0,0,0,0.05);
    border-color: rgba(black, .12);
  }
}

.card {
  @include card;

  position: relative;
  margin-top: 1.5em;
  padding: 24px 16px 16px;

  &__actions {
    position: absolute;
    top: 5px;
    right: 5px;

    > .btn {
      font-size: 16px;
      padding: 4px 13px;
      color: $gray;

      &:hover {
        color: $font-color;
      }
    }
  }
}

.report-card {
  @include shadow-box;

  text-align: center;
  margin-top: 1.5em;
  padding: 2em 1.25em 1em;
  background: white;
  border-radius: 3px;
}