.skills-map {
  flex: auto;
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  background: #fff !important;

  h1 {
    margin-bottom: 0;
    padding: 0 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-filter {
    flex: 0 0 auto;
    z-index: 100;
    top: 0;
    width: $skills-nav-width;
    padding: 6px 10px;
    margin-bottom: 15px;
    background: white;

    input {
      width: 100%;
      height: 32px;
      border-radius: 2px;
      border: none;
      box-shadow: none;
    }

    .full & {
      left: 0;
      top: $navbar-height;
      background: shade(#f5f5f5, 10%);
    }
  }

  &__tree {
    flex: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0 1.5em;
  }

  &__node {
    list-style: none;
  }

  &__parent {

  }

  &__children {
    display: none;
    padding: 0 0 0 1.5em;

    .skills-map__node_expanded > & {
      display: block;
    }
  }

  &__group {
    padding: 2em 1em;
  }

  &__name {
    display: block;
    line-height: normal;
    margin: 0;
    padding: .5em 0 .5em 20px;
    vertical-align: middle;

    &:before {
      @extend .fa;
      @extend .icon-file-alt;

      float: left;
      margin: 2px -100% 0 -18px;
    }

    .skills-map__parent > & {
      &:before {
        @extend .icon-folder-close;
        margin-left: -20px;
      }

    }

    &:hover {
      color: $blue;
      cursor: pointer;
    }

    .skills-map__node_expanded > & {
      &:before {
        @extend .icon-folder-open;
      }
    }
  }

  &__selected {
    color: $blue;

    &:before {
    }

    .skills-map__parent > & {
      &:before {
      }

    }

    .skills-map__node_expanded > & {
      &:before {
      }
    }
  }

  &__article {
    font-size: 16px;
    a {
      color: $blue;
    }
  }

  &__more {
    &-item {
      @include box($valign: top);

      margin-top: 1.5em;
      padding: 1.5em;
      text-align: center;
      cursor: pointer;
      border-radius: 3px;
      background: white;
      box-shadow: 0 0 0 1px rgba(black, .1);

      @include with-layout(60 4 split, true) {

        width: span(60);
        margin-right: gutter();
        margin-left: gutter();

        @include breakpoint($mobile) {
          width: span(30);
        }

        @include breakpoint($tablet) {
          width: span(20);
        }

        @include breakpoint($desktop-hd) {
          width: span(15);
        }

        @include breakpoint($desktop-uxga) {
          width: span(12);
        }
      }

      &:hover {
        .skills-map__more-name {
          color: $blue;
        }
      }
    }
  }

  &__path {
    font-size: 13px;
    margin: -.5em 0 .75em;
    white-space: nowrap;

    &-item {
      @include box;
      transition: background $transfast;

      position: relative;
      margin-right: 1px;
      padding: 3px 1.5em 3px .5em;
      cursor: pointer;
      color: $gray;
      background: #e6e6e6;

      &:before,
      &:after {
        @include pseudo;
        @include square(0);
        transition: border $transfast;

        position: absolute;
        z-index: 2;
        top: 0;
        right: 100%;
        border-style: solid solid solid none;
        border-width: 12px 12px 12px 0;
        border-color: transparent #e6e6e6 transparent transparent;
      }

      &:after {
        z-index: 1;
        border-right-color: white;
        right: 0;
      }

      &:hover {
        color: white;
        background-color: $blue;

        &:before {
          border-right-color: $blue;
        }
      }
    }
  }
}

.skills-nav {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 100%;
  background: #f7f8f9;
  border-right: 1px solid rgba(black, .1);

  .full & {
    top: $navbar-height * 2;
  }

  @include breakpoint($tablet) {
    display: flex !important;
    width: $skills-nav-width;
    padding: 0 0 1em;
    overflow-x: hidden;
  }

  &.sh-top {
    box-shadow: 0 6px 3px -3px rgba(black, .15) inset;
  }

  .skills-map-filter {
    width: auto;
  }
}

.skills-description {
  flex: auto;
  overflow-x: hidden;
  padding: 20px 20px 3em;

  &__title {
    font-size: 30px;
    margin: 0 0 .75em 0;
  }
}

.skills-group {
  &-description {
    margin-bottom: 1.75em;
  }
}

.subskills {
  padding-left: 23px;
}

.skills-card {
  &-title {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    overflow: auto;
  }
}

.skill-tags {
  &__item {
    font-size: 13px;
    margin-left: .5em;
    cursor: pointer;
    color: $gray;

    &:hover {
      color: $blue;
    }
  }
}

.loading-overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.skill-header {
  display: flex;
  align-items: center;

  .btn-show-navbar {
    cursor: pointer;
    margin-right: 1em;
    vertical-align: middle;

    @include breakpoint($tablet) {
      display: none;
    }

    &:hover {
      color: $blue;
    }
  }
}

.mastership-statistics {
  .header {
    display: flex;
    justify-content: center;
    padding: .5em 0 .5em 0;
    border-bottom: 1px solid #e5e5e5;

    .people {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      border-radius: 32px;
      background: $blue-smoke;
      color: $blue;
      white-space: pre;
      min-width: 5em;
      margin-right: 1.5em;
    }

    .stats {
      display: flex;
      margin-left: 1.5em;

      .stat {
        margin-right: 1em;
        align-self: center;
      }
    }
  }

  .table {
    .stars-header {
      text-align: center;
    }

    .stars {
      cursor: default;
      width: min-content;
      white-space: nowrap;
    }

    .owners {
      width: 6em;
      text-align: center;
    }

    .desc {
      width: 100%;
      padding-left: 2.5em;
    }

    .icon-star {
      cursor: default;
      color: #ccc;
    }

    .star-active {
      color: $blue;
    }

    th, td, p {
      vertical-align: middle;
    }

    p {
      padding-left: 1.5em;
      margin: 0;
    }

    ul {
      margin: 0;
    }
  }
}