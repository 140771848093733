.jstree-tt {
  .jstree-disabled .jstree-checkbox {
    opacity: 0;
    display: none;

    &,
    &:hover {
      background-position: -228px -4px;
    }
  }
  
  .jstree-leaf .jstree-disabled {
    color: #999;
    
    .jstree-checkbox {
      background-position: -228px -4px;
    }
  }

  .jstree-themeicon {
    &,
    &:empty {
      display: none;
    }

    &.icon-selected {
      display: inline-block;
      background-position: -228px -4px;
      background-image: url('../../jstree/themes/tt/32px.png');
    }
  }
}