.chart {
  padding: 24px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 15px 40px -5px rgba(0,0,0,0.05);

  &__title {
    text-align: center;
    margin: 0 0 20px;
  }

  &__controls {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}