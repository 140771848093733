.custom-checkbox {
  position: relative;
  display: inline-block;
  margin: 0;
  font-weight: normal;

  &__label {
    display: inline;
    font-weight: inherit;
    line-height: 20px;
    user-select: none;

    &:hover {
      .custom-checkbox__icon {
        background-position: -2px 21px;
      }

      .custom-checkbox__checkbox:checked + .custom-checkbox__icon {
        background-position: 25px 21px;
      }

      .custom-checkbox__checkbox[disabled] + .custom-checkbox__icon {
        background-position-y: 0;
      }
    }
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    line-height: 20px;
    cursor: pointer;
    vertical-align: middle;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA3CAMAAAB5LOkwAAABRFBMVEUAAAD///82qeG+4/XG5/fM6ffP6vjY7vnf8fri8/vj8/vs9/zw+f0tu0ZEwlpEwltFw1tGw1xGxFxHw11Iw15IxF5JxF5JxF9KxGBLxGBYyWxZyWxfy3Jgy3JizHRizHVjzHVkzXZkzXfG7MvH7c7Y7ffb8eHc3Nze8+Xf8Pjf8fjf8+Lf9OLg8fjg9eTh8fnh9eTi8vni9eTi9ubj8vnj9Obj9uXj9ubk9ufl9+fl9+nm9+nn9+nn+Oro9+vo+Ovq6urr9vrr9vvs9vrs9/vs9/zt7e3u+Pru+Pvv7+/v+Pvv+fvv+fzw8PDw+Pvw+fzw+f3y8vL19fX1+/329vb2+vz2+/74/Pv4/fz5+fn5/Pz5/P35/f36+vr6/Pz6/f36/f78/Pz8/vz9/vz9/v3+/v3+/v7+//3+//7//v7///7////GOSrYAAAADXRSTlMAAPz8/Pz8/Pz8/Pz8jMHXwQAAAcRJREFUeNrt1UlTwjAchnEUERCjggvu+76LqBVUUBQBFQXrgooUW4Ik3/9uIJoJ/DnQHjzxHDi8zG+mnUmmtg7T/SNZAdX+grNEovHknSgZj3ICZpkkM1lVlM0kOQGzTDJqLi/KqRlOwCyTbK6IRcVclhMwy0TNYyrCeZUTeY7MH7DZDAn70ZQ5EhpG/Rstk92JXaoMIu8RbpnMoL5FL/IptHUSGUMIDYeoCULDPuQPU1OE7s+eUZOEZZ1YODBWjqXlK2b2jlsntqZZIbA2aZPqTyfIVg3OErF3ObpFji47J2CWiWNpbU+0tuTgBMwycQYvH0RXQScnbL76G+9vL9gsEVfsWRM9x1ycSPP7R+Hx3CUTd1qjlFCelnZzwub3Eq7OuHw6t3qzJZMdRgghgKTeygZmonTsR5OJ9XpSIE3IeuLVYAYbyhDqX065IWE1kOsXfXN80wh40UDgIw0Ir+HBXnT2fVnwIt/htwZJhZuG19dPRqvfFwXTZqTyi2Si4S/Fh0YUTAggWqUWIBX8tT0dxgQQd43w6gkhWDcwaUqoSCYFwkxNkEI9ccWePkVP4sCAWT6WPZ5ekafHyQmcrV8xM1klPxrmVUCyyWLlAAAAAElFTkSuQmCC);
    background-position: -2px -2px;
  }

  &__checkbox {
    left: -9999px;
    position: absolute;

    &:checked {
      & + .custom-checkbox__icon {
        background-position: 25px -2px;
      }

      &:focus {
        & + .custom-checkbox__icon {
          background-position: 25px 21px;
        }
      }
    }

    &:not(:checked):focus {
      & + .custom-checkbox__icon {
        background-position: -2px 21px;
      }
    }
    &[disabled] {
      & + .custom-checkbox__icon {
        opacity: 0.8;
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        cursor: not-allowed;
      }
    }
  }

  .checkbox-group & {
    display: block;
    margin-bottom: .5em;
  }

  // Too long naming below. Prefer version above. Shoud be refactored in templates
  &-label {
    display: inline;
    font-weight: normal;

    &__check-icon {
      display: block;
      float: left;
      width: 24px;
      height: 24px;
      margin: -1px -100% 0 -29px;
      line-height: 24px;
      cursor: pointer;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA3CAMAAAB5LOkwAAABRFBMVEUAAAD///82qeG+4/XG5/fM6ffP6vjY7vnf8fri8/vj8/vs9/zw+f0tu0ZEwlpEwltFw1tGw1xGxFxHw11Iw15IxF5JxF5JxF9KxGBLxGBYyWxZyWxfy3Jgy3JizHRizHVjzHVkzXZkzXfG7MvH7c7Y7ffb8eHc3Nze8+Xf8Pjf8fjf8+Lf9OLg8fjg9eTh8fnh9eTi8vni9eTi9ubj8vnj9Obj9uXj9ubk9ufl9+fl9+nm9+nn9+nn+Oro9+vo+Ovq6urr9vrr9vvs9vrs9/vs9/zt7e3u+Pru+Pvv7+/v+Pvv+fvv+fzw8PDw+Pvw+fzw+f3y8vL19fX1+/329vb2+vz2+/74/Pv4/fz5+fn5/Pz5/P35/f36+vr6/Pz6/f36/f78/Pz8/vz9/vz9/v3+/v3+/v7+//3+//7//v7///7////GOSrYAAAADXRSTlMAAPz8/Pz8/Pz8/Pz8jMHXwQAAAcRJREFUeNrt1UlTwjAchnEUERCjggvu+76LqBVUUBQBFQXrgooUW4Ik3/9uIJoJ/DnQHjzxHDi8zG+mnUmmtg7T/SNZAdX+grNEovHknSgZj3ICZpkkM1lVlM0kOQGzTDJqLi/KqRlOwCyTbK6IRcVclhMwy0TNYyrCeZUTeY7MH7DZDAn70ZQ5EhpG/Rstk92JXaoMIu8RbpnMoL5FL/IptHUSGUMIDYeoCULDPuQPU1OE7s+eUZOEZZ1YODBWjqXlK2b2jlsntqZZIbA2aZPqTyfIVg3OErF3ObpFji47J2CWiWNpbU+0tuTgBMwycQYvH0RXQScnbL76G+9vL9gsEVfsWRM9x1ycSPP7R+Hx3CUTd1qjlFCelnZzwub3Eq7OuHw6t3qzJZMdRgghgKTeygZmonTsR5OJ9XpSIE3IeuLVYAYbyhDqX065IWE1kOsXfXN80wh40UDgIw0Ir+HBXnT2fVnwIt/htwZJhZuG19dPRqvfFwXTZqTyi2Si4S/Fh0YUTAggWqUWIBX8tT0dxgQQd43w6gkhWDcwaUqoSCYFwkxNkEI9ccWePkVP4sCAWT6WPZ5ekafHyQmcrV8xM1klPxrmVUCyyWLlAAAAAElFTkSuQmCC);
    }

    &__input {
      left: -9999px;
      position: absolute;

      &:checked {
        &+.custom-checkbox-label__check-icon {
          background-position: 27px 0;
        }

        &:focus {
          &+.custom-checkbox-label__check-icon {
            background-position: 27px 23px;
          }
        }
      }

      &:not(:checked):focus {
        &+.custom-checkbox-label__check-icon {
          background-position: 0 23px;
        }
      }
      &[disabled] {
        &+.custom-checkbox-label__check-icon {
          opacity: 0.8;
          filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          cursor: not-allowed;
        }
      }
    }
    &:hover {
      .custom-checkbox-label__check-icon {
        background-position: 0 23px;
      }

      .custom-checkbox-label__input:checked+.custom-checkbox-label__check-icon {
        background-position: 27px 23px;
      }

      .custom-checkbox-label__input[disabled]+.custom-checkbox-label__check-icon {
        background-position-y: 0;
      }
    }
  }
}