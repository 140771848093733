@import "sow";

.wskills-checkbox {
    margin-top: 10px;
}

.myprojects-error-message {
    color: red;
}

.project-description {
    width: 100%;
    resize: vertical;
}
