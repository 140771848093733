.footer {
  font-size: 12px;
  padding: 10px 20px;
  color: $gray;
  background: white;
  border-top: 1px solid rgba(black, .1);

  &__copy {
    margin-right: 2em;
  }

  &__feedback {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    i {
      margin-right: .5em;
    }
  }
}
