.navbar {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px 0 0;
  flex: 0 0 auto;
  min-height: $navbar-height;
  background: white;
  border-bottom: 1px solid rgba(black, .1);

  @include breakpoint($tablet) {
    padding-right: 40px;
  }

  &-brand {
    float: none;
    flex: 0 0 auto;
    display: none;
    padding: 0;
    text-align: left;
    width: auto;
    text-transform: none;

    span {
      font-family: 'Kaushan Script', cursive;
      color: $blue;
      text-shadow: none;
    }

    &.noBg {
      background: transparent;
    }

    @include breakpoint($tablet) {
      display: inline-block;
    }

    @include breakpoint(992px) {
      width: 120px;
      padding-left: 10px;
    }
  }

  &__link {
     &:hover {
      text-decoration: none;
     }

      .fa {
          font-size: 14px;
      }
  }

  &__username {
    padding-left: 1em;
    display: none;

    @include breakpoint(992px) {
      display: inline-block;
    }
  }

  &__settings {
    margin-right: 20px;
    vertical-align: middle;

    @include breakpoint(992px) {
      margin-right: 40px;
    }

    &,
    &:hover {
      text-decoration: none;
    }

    &:hover {
      color: $blue;
    }

    .icon {
      vertical-align: middle;
    }
  }

  &__logout {
    font-size: 24px;
    margin-left: 1em;
    color: rgba(black, .75);
    vertical-align: middle;
    transition: color .225s ease-out;

    &,
    &:hover {
      text-decoration: none;
    }

    &:hover {
      color: black;
    }
  }

  &-toggle {
    margin-right: 0;

    .icon-bar {
      background: $bg;
    }
  }

  &__right-side {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.dropdown {
    .dropdown-menu {
        left: auto;
        right: 0;
        max-width: inherit;
        min-width: 130px;
        max-height: calc(100vh - 44px);
        overflow: auto;
    }

    &__caret:before {
        @extend .fa;
        content: $fa-var-caret-up;
    }

     &:not(.open) &__caret:before {
        content: $fa-var-caret-down;
     }
}
