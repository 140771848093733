textarea {
	font-size: 100%;
}

.form {
  &-control {
    @include placeholder-color;
    width: 100%;
    box-shadow: none;

    font-size: 1em;
    display: inline-block;
    padding: .5em .75em;
    border-color: $border-color;
    border-radius: 3px;

    &:focus {
      box-shadow: none;
      border-color: $blue;

      + .input-group-addon {
        border-color: $blue;
      }
    }
  }
}

label {
  vertical-align: top;
}

select {
  &,
  &.form-control {
    appearance: none;
    padding-right: 2em;
    background-position: right 6px center;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAlklEQVQ4T+3SMQ5BQRDG8d+7hVLrEhqJ4h1ANCqFE0g0CuVzBJ2SAygkKqfQ6pyCbLISWdYWrxNTznzzn8w3U2kZVct+PwjYY4lLxpseVhg966kHfWwwxDWBdHHEFOccIORrrDHALQo7OGGOwys4d4VxFAdI0ITmBrt0tW9nnGESAdu42ps1pT9Y4B6nf/S1BCg+6h/AA/hvEhFscWXpAAAAAElFTkSuQmCC);
  }

  &[multiple] {
    background: none;
  }
}

.checkbox {
	font-weight: normal;
	margin-right: 1.5em;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eee;
}

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

textarea.form-control {
  height: auto;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="file"],
.uneditable-input {
  border-radius: 3px;
  box-shadow: none;
  border-color: $border-color;
}

.form-field_actions {
  margin-top: 1.5em;
}

.text-error {
  color: $red;
}

.input-group {
  display: flex;
  margin: 0;

  + .input-group {
    margin-left: 10px;
  }

  &-divider {
    margin: 0 10px;
    align-self: center;
  }

  &-addon {
    font-size: 13px;
    flex: 0 0 auto;
    width: auto;
    padding: 8px .75em 5px;
    line-height: 1.5;
    color: $gray;
    border-radius: 3px;
    border-color: $border-color;
    background: transparent;
    user-select: none;

    &:first-child {
      border-right-width: 0;
    }

    & + .form-control {
      border-left-width: 0;
    }
  }

  .form-control {
    flex: auto;
    // You can use flexbox in Firefox, they told me
    width: 1%;
    // Stretch the field
    height: auto;

    &:only-child {
      border-radius: 3px;
    }
  }

  &-inverse {
    flex-direction: row-reverse;

    .input-group-addon {
      transition: border-color ease-in-out 0.15s;

      &:last-child {
        border-radius: 3px 0 0 3px;
        border-left: 1px solid $border-color;
        border-right: 0;
      }
    }

    .form-control {
      border-left: none;

      &:first-child {
        border-radius: 0 3px 3px 0;
        padding-left: 0;

      }

      &:only-child {
        border-radius: 3px;
      }

      &:focus {
        + .input-group-addon {
          border-color: $blue;
        }
      }

      &:disabled {
        + .input-group-addon {
          background-color: #eee;
        }
      }
    }
  }
}

.control-label {
  margin-bottom: 10px;
  text-align: left !important;
}

.awesomplete {
    display: inherit !important;
}

.col-form-label {
  padding-top: 0.5rem;
  line-height: 1.3;
}