.autocomplete-suggestions {
  overflow-x: hidden;
  overflow-y: auto;
  cursor: default;
  padding: .5em 0;
  background: white;
  border: 1px solid $border-color;
  border-radius: 2px;
  box-shadow: 0 6px 10px -2px rgba(black, .15);
}

.autocomplete-suggestion {
  font-size: 13px;
  padding: 8px 16px 8px 32px;
  line-height: 1.3;
}

.autocomplete-selected {
  background: #ececec;
}

.autocomplete-group {
  font-size: 11px;
  font-weight: bold;
  padding: 12px 8px 4px 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}