// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$emoticons-lg-order1-name: 'emoticons-lg-order1';
$emoticons-lg-order1-x: 0px;
$emoticons-lg-order1-y: 0px;
$emoticons-lg-order1-offset-x: 0px;
$emoticons-lg-order1-offset-y: 0px;
$emoticons-lg-order1-width: 36px;
$emoticons-lg-order1-height: 36px;
$emoticons-lg-order1-total-width: 112px;
$emoticons-lg-order1-total-height: 72px;
$emoticons-lg-order1-image: '../img/emoticons.png';
$emoticons-lg-order1: (0px, 0px, 0px, 0px, 36px, 36px, 112px, 72px, '../img/emoticons.png', 'emoticons-lg-order1', );
$emoticons-lg-order2-name: 'emoticons-lg-order2';
$emoticons-lg-order2-x: 36px;
$emoticons-lg-order2-y: 0px;
$emoticons-lg-order2-offset-x: -36px;
$emoticons-lg-order2-offset-y: 0px;
$emoticons-lg-order2-width: 36px;
$emoticons-lg-order2-height: 36px;
$emoticons-lg-order2-total-width: 112px;
$emoticons-lg-order2-total-height: 72px;
$emoticons-lg-order2-image: '../img/emoticons.png';
$emoticons-lg-order2: (36px, 0px, -36px, 0px, 36px, 36px, 112px, 72px, '../img/emoticons.png', 'emoticons-lg-order2', );
$emoticons-lg-order3-name: 'emoticons-lg-order3';
$emoticons-lg-order3-x: 0px;
$emoticons-lg-order3-y: 36px;
$emoticons-lg-order3-offset-x: 0px;
$emoticons-lg-order3-offset-y: -36px;
$emoticons-lg-order3-width: 36px;
$emoticons-lg-order3-height: 36px;
$emoticons-lg-order3-total-width: 112px;
$emoticons-lg-order3-total-height: 72px;
$emoticons-lg-order3-image: '../img/emoticons.png';
$emoticons-lg-order3: (0px, 36px, 0px, -36px, 36px, 36px, 112px, 72px, '../img/emoticons.png', 'emoticons-lg-order3', );
$emoticons-lg-order4-name: 'emoticons-lg-order4';
$emoticons-lg-order4-x: 36px;
$emoticons-lg-order4-y: 36px;
$emoticons-lg-order4-offset-x: -36px;
$emoticons-lg-order4-offset-y: -36px;
$emoticons-lg-order4-width: 36px;
$emoticons-lg-order4-height: 36px;
$emoticons-lg-order4-total-width: 112px;
$emoticons-lg-order4-total-height: 72px;
$emoticons-lg-order4-image: '../img/emoticons.png';
$emoticons-lg-order4: (36px, 36px, -36px, -36px, 36px, 36px, 112px, 72px, '../img/emoticons.png', 'emoticons-lg-order4', );
$emoticons-order1-name: 'emoticons-order1';
$emoticons-order1-x: 72px;
$emoticons-order1-y: 0px;
$emoticons-order1-offset-x: -72px;
$emoticons-order1-offset-y: 0px;
$emoticons-order1-width: 20px;
$emoticons-order1-height: 22px;
$emoticons-order1-total-width: 112px;
$emoticons-order1-total-height: 72px;
$emoticons-order1-image: '../img/emoticons.png';
$emoticons-order1: (72px, 0px, -72px, 0px, 20px, 22px, 112px, 72px, '../img/emoticons.png', 'emoticons-order1', );
$emoticons-order2-name: 'emoticons-order2';
$emoticons-order2-x: 72px;
$emoticons-order2-y: 22px;
$emoticons-order2-offset-x: -72px;
$emoticons-order2-offset-y: -22px;
$emoticons-order2-width: 20px;
$emoticons-order2-height: 22px;
$emoticons-order2-total-width: 112px;
$emoticons-order2-total-height: 72px;
$emoticons-order2-image: '../img/emoticons.png';
$emoticons-order2: (72px, 22px, -72px, -22px, 20px, 22px, 112px, 72px, '../img/emoticons.png', 'emoticons-order2', );
$emoticons-order3-name: 'emoticons-order3';
$emoticons-order3-x: 72px;
$emoticons-order3-y: 44px;
$emoticons-order3-offset-x: -72px;
$emoticons-order3-offset-y: -44px;
$emoticons-order3-width: 20px;
$emoticons-order3-height: 22px;
$emoticons-order3-total-width: 112px;
$emoticons-order3-total-height: 72px;
$emoticons-order3-image: '../img/emoticons.png';
$emoticons-order3: (72px, 44px, -72px, -44px, 20px, 22px, 112px, 72px, '../img/emoticons.png', 'emoticons-order3', );
$emoticons-order4-name: 'emoticons-order4';
$emoticons-order4-x: 92px;
$emoticons-order4-y: 0px;
$emoticons-order4-offset-x: -92px;
$emoticons-order4-offset-y: 0px;
$emoticons-order4-width: 20px;
$emoticons-order4-height: 22px;
$emoticons-order4-total-width: 112px;
$emoticons-order4-total-height: 72px;
$emoticons-order4-image: '../img/emoticons.png';
$emoticons-order4: (92px, 0px, -92px, 0px, 20px, 22px, 112px, 72px, '../img/emoticons.png', 'emoticons-order4', );
$emoticons-width: 112px;
$emoticons-height: 72px;
$emoticons-image: '../img/emoticons.png';
$emoticons-sprites: ($emoticons-lg-order1, $emoticons-lg-order2, $emoticons-lg-order3, $emoticons-lg-order4, $emoticons-order1, $emoticons-order2, $emoticons-order3, $emoticons-order4, );
$emoticons: (112px, 72px, '../img/emoticons.png', $emoticons-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
