.common-settings {
  width: 27em;
}

.notification-settings {
  .settings-block {
    margin: 1em 0 3em;

    &__title {
      margin: 2em 0 1em;
    }

    &__option {
      margin: .75em 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    label {
      vertical-align: baseline;
    }
  }

  .notify-in-days-container {
    margin-top: .8em;

    .notify-in-days-text {
      margin: 0em .3em;
    }

    .inline-field {
      background: transparent;
      border: 0px;
      height: 2.75em;
      padding: 0.5em 0.75em;

      &__number {
        height: 2em;
        margin: 0 0 .3em .5em;
        padding: .3em 0em .3em .3em;
        width: 3em;
        border-bottom: 1px solid $blue;
        border-radius: 0;
        text-align: center;
        -moz-appearance: textfield;

        &:hover {
          -moz-appearance: textfield;
        }

        &:focus {
          width: 4em;
          -moz-appearance: none;
          background: white;
          border: 1px solid $blue;
          border-radius: .2em;
        }
      }

      &__notifiable-days {
        width: 15.2em;
      }
    }
    .select2-container {
      min-width: 8em;
      width: inherit !important; // '!' is here to override the 'width: 212.8px' - base style of select2-container element

      .select2-selection {
        border: none;

        &__rendered {
          border-bottom: 1px solid $blue;
          border-radius: 0;
        }

        &__choice {
          background: transparent;
          color: $font-color;
          padding-top: .3em;
          padding-bottom: .2em;

          &__remove {
            float: right;
            color: $font-color;
            margin-right: 0em;
            margin-left: .3em;

            &:hover {
              color: $font-color;
            }
          }
        }
      }

      &--focus,
      &--open {
        .select2-selection {
          border: 1px solid $blue;

          &__rendered {
            border-bottom: none;
            border-radius: .2em;
          }

          &__choice {
            background: $blue;
            color: white;
            padding-top: .4em;

            &__remove {
              float: right;
              color: white;
              margin-right: 0em;
              margin-left: .3em;

              &:hover {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}