.permission-denied-block {
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text-align-block-center {
  text-align: center;
}

.error {
  font-size: 12px;
  font-weight: normal;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  &__inner {
    position: relative;
    max-width: 34em;
    padding: 120px 3em 40px;
  }

  &__code {
    font-family: inherit;
    font-size: 6em;
    font-weight: 800;
    line-height: 1.1;
    position: relative;
  }

  &__name {
    font-size: 2em;
    position: relative;
  }

  &__details {
    font-size: 1.5em;
    position: relative;
    padding-top: .5em;
  }

  &__img {
    width: 120px;
    height: 280px;
    position: absolute;
    transform: translate3d(120%, -30%, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="149.432" height="271.646"><path fill-rule="evenodd" clip-rule="evenodd" fill="#FBB040" d="M129.084 66.246L69.67 80.496S90.735 21.946 95.204 9.96C99.31-1.055 86.906.033 86.906.033h-47.01c-7.894-.6-8.468 7.327-8.468 7.327S2.875 120.325.348 131.92c-2.528 11.596 9.583 8.56 9.583 8.56l62.415-14.54S42.988 247.006 42.208 248.75c-.78 1.74-1.855 10.374 4.154 10.792 6.01.418 9.525-5.826 9.525-5.826S133.06 89.446 138.29 76.503c5.23-12.943-9.206-10.257-9.206-10.257z"/><path fill-rule="evenodd" clip-rule="evenodd" fill="#FAC43C" d="M139.085 78.33L79.67 92.58s21.065-58.55 25.534-70.535c4.107-11.017-8.297-9.93-8.297-9.93h-47.01c-7.895-.598-8.47 7.328-8.47 7.328s-28.552 112.967-31.08 124.56c-2.527 11.597 9.584 8.562 9.584 8.562l62.416-14.542S52.99 259.09 52.208 260.833c-.78 1.74-1.855 10.375 4.154 10.793 6.01.418 9.525-5.826 9.525-5.826S143.06 101.53 148.29 88.587c5.23-12.943-9.205-10.257-9.205-10.257z" opacity=".4"/></svg>');

  }

  @include breakpoint($tablet) {
    font-size: 16px;
    padding-left: 150px;

    &__inner {
      padding-top: 60px;
    }

    &__img {
      width: 150px;
      height: 300px;
      transform: translate3d(-100%, -40%, 0);
    }
  }
}
