.grid {
  &-columnpicker {
    display:none;
    font-size: 13px;
    position: fixed;
    z-index:20;
    min-width: 160px;
    margin-top: -1px;
    padding: 10px 20px;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.25);
    cursor: default;
    border: 1px solid shade($smoke, 5%);
    background: white;

    hr + li {
      margin-top: 0;
    }

    &__separator {
      margin: 15px -20px;
    }

    &__checkbox {
      padding-top: .5em;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}