.departments-list {
    margin-left: 1em;

    .departments-header {
        display: inline-block;
        margin: 1em 0;
    }

    .add-department-btn {
        float: right;
        margin-top: 1em;
    }

    .add-department {
        display: inline-block;

        .add-department-form,
         .add-title-form {
            .add-modal-title {
                font-size: 1.2em;
            }

            .name-en,
             .alternative-name,
             .edit-date-created {
                margin-bottom: .5em;
            }

            .input-name,
             .input-alternative-name,
             .input-date-created {
                margin-bottom: 1em;
            }
        }
    }

    .delete-department-form,
     .delete-title-form {
        display: inline-block;

        .delete-modal-title {
            font-size: 1.2em;
        }

        .delete-department-btn,
         .delete-title-btn {
            font-size: 1.2em;
            margin: 0 .3em 0 0;
            color: lighten($gray, 20%);

            &:hover {
                color: $gray;
                cursor: pointer;
            }

            &.disabled {
              color: #d1d1d1;
              &:hover {
                cursor: default;
              }
            }
        }

        .date-abolition-label {
            margin-bottom: .5em;
        }
    }

    .department-item {
        padding-bottom: 1em;
        margin-bottom: 1em;
        border: 1px solid rgb(236, 236, 236);

        .add-title-btn {
            margin-left: 1.5em;
        }

        .add-title-form {
            .add-modal-title {
                font-size: 1.2em;
            }

            .name-en,
             .alternative-name,
              .edit-date-created {
                margin-bottom: .5em;
            }

            .input-name,
             .input-alternative-name,
              .input-date-created {
                margin-bottom: 1em;
            }
        }

        .department-info {
            cursor: pointer;
            background-color: rgb(236, 236, 236);
            padding: .5em;

            .department-info-header {
                .names {
                    display: inline-block;
                    font-size: 1.2em;
                    padding-left: .2em;
                }

                .date-created {
                    font-size: .9em;
                    color: lighten($gray, 20%);
                    display: inline-block;
                    float: right;
                    margin-top: .3em;
                }

                .collapse-icon {
                    display: inline-block;
                    float: right;
                    margin: 0 .5em;
                    padding: 0 .5em;
                    background-color: lighten($gray, 20%);
                    border-radius: .2em;

                    .collapse-titles-icon {
                        transition: transform 0.5s;
                        display: inline-block;
                        font-size: 1.2em;
                        color: rgb(236, 236, 236);

                        transform: rotate(0deg);
                    }
                }
            }
        }

        .titles-list {
            transition: all .4s linear;
            width: 100%;
            height: 100%;
            opacity: 1;
            padding: .5em .5em 1em;

            .header-name,
             .header-alternative-name {
                width: 34%;
            }

            .table {
                height: 100%;

                .titles-table {
                    .header-name,
                     .header-alternative-name {
                        width: 50%;
                    }
                }

                tr, td, th {
                    transition: all .2s linear;
                    font-size: auto;
                    padding: auto;
                    max-height: 100%;
                }
            }
        }
    }

    .minimized {
        .titles-list {
            transition: all .5s linear;
            width: 0;
            height: 0;
            opacity: 0;
            overflow: hidden;
            margin: 0;
            padding: 0;

            .table {
                transition: height .5s linear;
                height: 0;

                tr, td, th {
                    transition: all .5s linear;
                    font-size: 0;
                    padding: 0;
                    max-height: 0;
                }
            }
        }

        .collapse-titles-icon {
            transform: rotate(-180deg) !important;
        }
    }

    .department-actions,
     .title-actions {
        display: inline-block;
        float: left;
        padding: .7em 0 0 .4em;

        .edit-department-form,
         .edit-title-form {
            display: inline-block;

            .edit-department-btn,
             .edit-title-btn {
                font-size: 1.2em;
                margin: 0 .3em;
                color: lighten($gray, 20%);

                &:hover {
                    color: $gray;
                    cursor: pointer;
                }
            }
        }

        .edit-department-modal,
         .edit-title-modal {
            .name,
             .alternative-name,
             .edit-date-created {
                margin-bottom: .5em;
            }

            .input-name,
             .input-alternative-name,
             .input-edit-date-created {
                margin-bottom: 1em;
            }
        }
    }
}

.date-picker-wrapper {
    z-index: 100000 !important;
}

.employee-titles-block {
    .edit-job-title-modal,
     .add-job-title-form {
        .comment {
            display: block;
        }

        .input-comment {
            width: 100%;
            max-width: 100%;
        }

         .department-select,
         .title-select,
         .company-select,
         .office-select,
         .unit-select,
         .employment-type-select,
         .group-select,
         .action-select,
         .location-select,
         .input-start-date,
         .input-end-date,
         .input-comment,
         .input-weekly-hour-norm,
         .dismissal-reason-select {

            .disabled-option {
                display: none;
            }

            &:invalid {
                color: lighten($gray, 20%);

                option {
                    color: darken($gray, 20%);
                }
            }
        }

        .dismissal-reason-block {
            display: none;
        }
    }

    .add-job-title-btn {
        margin-bottom: 1em;
    }

    .employee-titles-table {

        td, tr {
          padding: 6px;
        }

        .department,
         .title {
            width: 19%;
        }

        .location {
            width: 10%;
        }

        .edit {
            width: 4em;
        }

        .comment {
          color: $gray;
          font-size: 1.2em;
          margin: 0 .3em;
          &:hover {
              cursor: pointer;
            }
        }

        .edit-job-title-btn {
            display: inline-block;
            font-size: 1.2em;
            margin: 0 .3em;
            color: lighten($gray, 20%);

            &:hover {
              color: $gray;
              cursor: pointer;
            }
        }
    }
}

.location-settings,
 .dismissal-reason-settings {
    padding: 20px;
    margin-bottom: 3em;

    .locations-header,
     .dismissal-reasons-header {
        display: inline-block;
        margin: 1em 0;
    }

    .add-location-btn,
     .add-dismissal-reason-btn {
        margin-top: 1em;
        float: right;
    }

    .locations-table,
     .dismissal-reasons-table {
        .edit-location-btn,
         .delete-location-btn,
         .edit-dismissal-reason-btn,
         .delete-dismissal-reason-btn{
            display: inline-block;
            font-size: 1.2em;
            margin: 0 .3em;
            color: lighten($gray, 20%);

            &:hover {
                color: $gray;
                cursor: pointer;
            }
        }
    }

    .edit-location,
     .delete-location,
     .edit-location-icon,
     .delete-location-icon {
        display: inline-block;
    }

    .edit-dismissal-reason,
     .delete-dismissal-reason,
     .edit-dismissal-reason-icon,
     .delete-dismissal-reason-icon {
        display: inline-block;
    }
}

.noty-of-empty-collection {
    position: absolute;
    left: 45%;
    margin-top: .5em;
}

.noty-of-empty-table {
    text-align: center;
}
