.box {
	.box-header {
		@include clearfix;
		overflow: visible;
    background: $blue;

    h2 {
      font-size: 16px;
    }

		.dropdown {
			display: inline-block;
		}

		.dropdown-menu {
			box-shadow: 0 2px 15px rgba(0,0,0,.25);
			left: auto;
			right: -.35em;
			border-radius: 0;
			border-color: $blue;
			border-top: none;
		}
	}

  .box-content {
    border-radius: 4px;
    padding: 10px 15px;

    @include breakpoint($tablet) {
      padding: 15px 25px;
    }


    #employmentSample {
      width: 29em;
      height: 11em;
      margin-left: -0.3em;
      margin-top: 1.0em;
    }

    .sort-radio {
      float: left;
      margin-left: 1em;
      margin-right: 1em;
    }
  }
}

.scrollbar-overflow {
  height: 50em;
  overflow-y: scroll;
}
