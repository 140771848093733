.login {

  &-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #DFF1FF url('../../img/bg.jpg');
    background-size: cover;
    text-align: center;
    align-content: center;
    justify-content: center;
  }

  &-box {
    @include box($valign: middle);
    box-shadow: 0 2px 0 rgba(black, .2);
    align-self: center;
    width: 26em;
    max-width: 100%;
    text-align: left;
    border-radius: 3px;

    input[type="text"],
    input[type="password"] {
      border: none;
      padding: 0px 10px;
      height: 40px;
      margin: 5px auto;
      border-radius: 2px;
      background: rgba(0,0,0,.2);
    }

    .remember {
      margin-top: 20px;
      float: left;
    }

    .box-content {
      background: rgba(white, .35);
    }

    .checkbox {
      @include box($valign: baseline);
      margin: 0;
      padding: 0;

      input[type="checkbox"] {
        float: none;
        margin: 0 .5em 0 0;
      }
    }

    .errorlist {
      color: $red;
      list-style: none;
      margin: 0 0 1.5em;
      padding: 0;
    }

    .form-group {
      position: relative;

      .errorlist {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .form-submit {
      line-height: .1;
      text-align: justify;

      &:after {
        @include pseudo;
        width: 100%;
      }
    }

    .logo {
      font-size: 1.75em;
      display: block;
      text-align: center;
      padding: .25em;
    }
  }
}
