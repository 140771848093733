.toolbar {
  flex: 0 0 auto;
  position: relative;
  padding: 5px 15px;
  border-bottom: 1px solid shade($smoke, 10%);

  @include breakpoint($tablet) {
    padding: 10px 24px;
  }

  &__group {
    display: inline-flex;
    flex-wrap: wrap;
    vertical-align: middle;
    margin-top: 5px;
    margin-right: 20px;

    &__mobile {
      width: 100%;

      @include breakpoint($desktop-hd) {
        width: 25em;
      }
    }

    @include breakpoint($tablet) {
      margin-top: 0;
      margin-bottom: 0;

    }

    &:last-child {
      margin-right: 0;
    }

    &_pos {
      &_right {
        float: right;
        margin-right: 0;
        margin-left: 20px;
      }
    }
  }

  .emp-counter {
    position: absolute;
    top: 100%;
    left: 50%;
    height: 32px;
    margin: 0;
    line-height: 32px;
    text-align: center;
    padding: 0 1em;
    background: white;
    border-radius: 32px;
    border: 1px solid shade($smoke, 10%);
    transform: translate3d(-50%, -50%, 0);
    width: auto;

    &:empty {
      display: none;
    }
  }
}

.emp-counter {
  width: 100%;
  text-align: center;
  margin: 0.5em 0;
}