.scrollbar-overflow {
  padding-top: 0 !important;
}

#main-menu-toggle {
  @include user-select;
  font-size: 16px;
  z-index: 1000;
  top: 0px;
  margin: 0;
  padding: 11px 15px;
  text-shadow: none;
  text-decoration: none;
  cursor: pointer;

  @include breakpoint($tablet) {
    left: 10px;
  }

  @include breakpoint(992px) {
    display: none;
    left: 0;
  }
}




