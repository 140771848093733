.modal {
  text-align: center;
  padding: 0!important;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  &-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  &-content {
    box-shadow: 0 5px 20px rgba(0,0,0,.175);
    display: block;
    border: none;
    border-radius: 10px;
    overflow: hidden;
  }

  &-header {
    padding: 1.5rem 1.5rem 0;
    color: $font-color;
    background: transparent;
    border: none;
  }

  &-title {
    font-size: 21px;
    font-weight: bold;
    margin: 0;
    line-height: 1.428571429;
  }

  &-body {
    padding: 1.5rem;
  }

  &-footer {
    margin: 0;
    padding: 1rem 1.5rem;
    text-align: right;
    background: #F7F8F9;
    border-top: 1px solid rgba(0,0,0,.05);
  }

  &-backdrop {
    background: black;

    &.in {
      opacity: 0.25;
    }
  }
}