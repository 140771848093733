.wizard {

  &-step {

    &__title {
      font-size: 20px;
      margin: 0 0 1.5em;
      line-height: normal;
    }

    &__buttons {
      text-align: center;
      margin-top: 1.5em;
      padding-top: 1.5em;
      border-top: 1px solid $smoke;

      .btn {
        margin: 0 1em;
      }
    }
  }
}