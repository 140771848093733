@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: tint($background, 10%);
        border-color: shade($border, 10%);
  }
  &:hover {
    color: $color;
    background-color: tint($background, 10%);
        border-color: shade($border, 10%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: shade($background, 10%);
        border-color: shade($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: shade($background, 10%);
          border-color: shade($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }
}

.btn {
  transition: all ease-in-out 0.15s,
              box-shadow ease-in-out 0.15s,
              background-color ease-in-out 0.15s;

  & + .btn {
    margin-left: 1em;
  }

  .icon {
    margin-right: 6px;
  }

  &:focus,
  &:active {
    &,
    &:hover {
      outline: none;
    }
  }

  &:active {
    box-shadow: none;
  }

  &-default {
    border: 1px solid shade($smoke, 5%);
    background: white;

    &:hover {
      border-color: tint($blue, 50%);
      background: white;
      color: shade($blue, 10%);
    }

    &:active,
    &:focus {
      &,
      &:hover {
        border-color: tint($blue, 35%);
        background: white;
        box-shadow: none;
        color: shade($blue, 10%);
      }
    }

    &.active {
      box-shadow: none;

      &,
      &:active,
      &:focus {
        &,
        &:hover {
          background: $blue;
          color: white;
          border-color: shade($blue, 15%);
        }
      }
    }

    .btn-group & {
      &.active + .btn-default {
        border-left-color: shade($blue, 15%);
      }
    }
  }

  &-success {
    @include button-variant(white, $success-color, shade($success-color, 10%));
  }

  &-primary {
    @include button-variant(white, $blue, shade($blue, 10%));
    border: none;
  }

  &-dashed {
    @include button-variant($font-color, white, shade($smoke, 30%));
    border-width: 1px;
    border-style: dashed;
    border-radius: 4px;

    &:hover,
    &:active,
    &:focus {
      &,
      &:hover {
        border-color: tint($blue, 50%);
        background: white;
        box-shadow: none;
        color: shade($blue, 10%);
      }
    }
  }

  &-plain {
    border: 1px solid transparent;
    background: white;

    &:hover {
      background: tint($smoke, 25%);
    }

    &:active,
    &:focus {
      &,
      &:hover {
        background: tint($smoke, 15%);
        box-shadow: none;
      }
    }
  }

  .fa-plus {
    font-size: 10px;
    margin-right: 5px;
  }

  &-sm {
    font-size: 13px;
  }

  &-lg {
    font-size: 16px;
    padding: 8px 14px;
    line-height: 1.428571429;
  }
}

.button-text-arrow {
	display: inline-block;
	height: .5em;
	line-height: .25;
	font-size: 28px;
	vertical-align: middle;
}

.btn-delete-left {
    float: left;
    // margin-right: 280px;
}

.btn:disabled {
  background-color: rgba(0,0,0,0.2);
}

.btn-group {
  + .btn-group {
    margin-left: .75em;
  }
}

.new-item-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: rgba(black, .05);
  border-color: transparent;
  transition: all .255s;

  @include breakpoint($phablet) {
		flex-direction: column;
	}

  &__icon {
    font-size: 24px;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 44px;
    border: 1px solid rgba(black, .1);
    border-radius: 50%;
    background: rgba(white, .75);
    transition: all .255s;
  }

  &__label {
    line-height: 32px;
    padding: .25em 1.5em;
    background: white;
    border-radius: 32px;
    transition: all .255s;
  }

  &:hover {
    text-decoration: none;
    border-color: rgba(black, .05);

    .new-item-btn__icon {
      transform: scale(1.2);
    }

    .new-item-btn__label {
      box-shadow: 0 15px 40px -5px rgba(0,0,0,0.05);
    }
  }
}