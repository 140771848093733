.resources-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.resources-item {
    clear: left;
	@include user-select;
	margin-bottom: 1em;
	padding: .5em .5em 1em;
	background: #fff;
	cursor: move;
	padding-left: 40px;
	border-bottom: 1px solid #e3e3e3;

	&:last-child {
		border: none;
		margin-bottom: 0;
	}

    .plot-employment {
        height: 4em;
    }

	.userpic {
		float: left;
		margin: 0 -100% 0 -40px;
	}

	.techs {
		display: block;
		font-size: 12px;
	}

}

.project-positions-item {
	min-height: 5em;
	margin-bottom: .5em;
	padding: .25em .5em;
	border: 1px solid #e3e3e3;
	white-space: normal;

	&.positive {
		border-color: $green;
		border-style: dashed;
	}

	&.over {
		border-color: $blue;
		border-style: dashed;
	}

    .hours {
        /*float: left;*/
    }

	.resources-item {
		display: inline-block;
		position: relative;
		margin: .25em;
		padding: 0;
		border: none;
		cursor: pointer;

		.userpic {
			float: none;
			margin: 0;
		}

		&:after {
			content: "";
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: url('../../img/cross.png');
			border-radius: 100%;
		}

		&:hover {
			&:after {
				display: block;
			}
		}
	}

	.username,
	.techs,
    .plot-employment,
	.utilization {
		display: none;
	}

}

.project-positions-label {
    /*float: left;*/
    margin-right: 0.7em;
	font-size: 12px;
	color: #7f7f7f;
	display: block;
	//pointer-events: none;
}

.planned-projects {
	white-space: nowrap;
	overflow-x: auto;

	.col-lg-3 {
		display: inline-block;
		float: none;
		vertical-align: top;
	}
}
