.skills-chart {

  &__chart {
    @include rect(100%, 21em);

    display: block;
  }

  &__selector {
  }

  &__legend {
    padding: 2em;
  }

  &__legend-item {
    @include box;

    margin: 1em 2em 0 0;

    .remove-cross {
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      margin: -9px 0 0;
      text-align: center;
    }
  }

  &__legend-color {
    @include circle(18px);
    @include box($valign: middle);

    margin-right: .5em;
  }

  &__tooltip {
    font-weight: 300;
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
    margin: -10px 0 0 0;
    padding: .25em .5em;
    color: white;
    border-radius: 2px 2px 0 2px;
    background-color: $blue;

    &_visible {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      @include pseudo;
      @include square(0);

      position: absolute;

      right: 0;
      top: 100%;

      border-style: solid;
      border-width: 5px 0 0 5px;
      border-color: $blue transparent transparent;
    }
  }
}