.loader__inner {
    width: 100%;
    text-align: center;
    flex: 1 0 auto;
    align-self: center;
}

.loader {
    grid-column: 1 / -1;
    flex: 1 0 auto;
    width: 100%;
    text-align: center;
    align-self: center;
}
