.skill-ratings {
  @include box;

  &__item {
    @include clearfix;

    margin: 0 0 .25em;
    padding: 0 .5em 0 .25em;
  }

  &__text {
    margin-left: 1em;
    text-align: right;
  }

  &__total {
    margin-top: .5em;
    padding: .5em .5em 0;
    text-align: right;
    border-top: 1px solid $smoke;
  }

  .icon-star {
    margin: 0 .5em;
  }
}

.skill-owners {
  margin-left: 20px;

  .skill-attitude {
    .attitude {
      display: inline-block;
    }
  }
}