.newcomment {
    width: 100%;
    margin-bottom: 1em;
    textarea {
        min-width: 500px;
        max-width: 100%;
    }
    button {
        width: 100px;
        margin: 5px 0;
    }
    .form-group {
        width: 100%;
    }
}
.comments {
    .delcomment {
        float: right;
        color: red;
        font-weight: bold;
    }
    .date {
        font-size: smaller;
        float: right;
    }

    .text {
        white-space: pre-wrap;
    }
}