$pic_color : #C5C7C5;


.utilization-amount {
	@extend .inline-block;
	width: 6em;
	margin-right: 1em;
}

.utilization-hours {
	@extend .inline-block;
	width: 9em;
	margin: 0 1em;

	.form-control {
		text-align: right;
	}
}

.utilization-period {
	@extend .inline-block;
	width: 8em;
	margin: 0 1em;
}

.utilization-table {
	.utilization-cell {
		padding: 0;
	}

	.right {
		text-align: right;
	}
}

.utilization-project {
	box-sizing: border-box;
	display: block;
	float: left;
	overflow-x: hidden;
	height: 3em;
	position: relative;
	background: #27ae60;
	border-left: 1px solid #fff;
	white-space: nowrap;

	&:first-child {
		border: none;
	}

	&:hover {
		cursor: pointer;
		background-color: transparentize(#27ae60, 0.25);
	}
}
.utilization-project__title {
	font-size: 75%;
	margin: .25em;
	color: #fff;
}

.utilization-idle {
	background: #e74c3c;

	&:hover {
		background-color: transparentize(#e74c3c, 0.25);
	}
}

.utilization-vacation {
	background: #95a5a6;

	&:hover {
		background-color: transparentize(#95a5a6, 0.25);
	}
}

.planning-conflict {
	color: red;
}

.page-header .filter-header{
    @include clearfix;

    h1 {
        float: left;
    }

    .filter {
        .text-filter {
            color: black !important;
            float: left;
            display: block;
            margin-left: 1em;
            width: 25em;
        }

        .search-pic {
            display: inline-block;
            background-color: $pic_color;
            width: 2.1em;
            height: 2.4em;
            float: left;

            text-align: center;

            .icon-search {
                display: inline-block;
                // text-align: center;
                margin-top: .7em;
            }
        }

        .form-control {
            width: 20em;
            margin-left: 1.5em;
            // display: block;
            float: left;

        }
    }
}
