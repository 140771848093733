.filter {
  &:last-child {
    margin-bottom: 0;
  }

  &-panel {
    flex: 0 0 auto;
    display: flex;
    padding: 0;
    margin: 0;
    background: white;
    border-left: 1px solid $border-color;

    &__content {
      flex: auto;
      overflow-x: hidden;

      .filter-panel_pos_aside & {
        width: 26em;
      }
    }
  }

  &-block {
    padding: 1em 1.5em .5em;
  }
}

.filter-menu {
  min-width: 23em;
  max-width: 65em;
  background: white;
  box-shadow: 0 15px 40px -5px rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  &__footer {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: #f7f8f9;

    .btn:only-child {
      margin-left: auto;
    }
  }
}

.checkbox-filter {
  &__options-list {
    display: grid;
    padding: 16px;
    grid-column-gap: 12px;
    grid-template-columns: repeat(1, 1fr);

    @include breakpoint($phablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint($desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__options-item {
    padding: 4px;
  }
}

.datalist-filter {

  &__search {

    input {
      height: 44px;

      &,
      &:focus {
        border-color: transparent;
      }
    }
  }

  &__selected-option {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 40px 8px 16px;
    border-top: 1px solid rgba(black, .1);

  }

  &__option-name {
    flex: auto;
    margin-right: 16px;
  }

  &__remove {
    font-size: 24px;
    font-weight: 300;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    transform: translateY(-50%);
    line-height: 18px;
    color: $gray;
    cursor: pointer;
  }
}
