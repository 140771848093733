$avatar-sizes: (
  xxl: 120px,
  xl: 96px,
  l: 72px,
  m: 42px,
  s: 32px,
  xs: 24px,
  xxs: 18px
);

.avatar {
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(black, .05);

  @each $key, $value in $avatar-sizes {
    &_size_#{$key} {
      @include square($value);
    }
  }

  &_size_available {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}