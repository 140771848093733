@keyframes spinx {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@mixin spin {
  @include animation(spinx 2s infinite linear);
}