.tt-popover {
	box-shadow: 0 2px 15px rgba(0,0,0,.25);
	display: none;
	position: absolute;
	z-index: 10;
	max-width: 32em;
	margin: -12px 0 0;
	background: #fff;
	color: #383e4b;

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		overflow: hidden;
		top: 100%;
		left: 50%;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0;
	}


	&:before {
		border-color: #36a9e1 transparent transparent;
		margin-left: -8px;
	}

	&:after {
		border-color: #fff transparent transparent;
		margin: -1px 0 0 -8px;
	}

	&.tt-popover_dir_right {
		&:before,
		&:after {
			left: auto;
			right: 1em;
		}
	}

	&.is-visible {
		display: block;
	}
}

.tt-popover__title {
	padding: .5em 1em;
	background: #36a9e1;
	color: #fff;
	text-transform: uppercase;
}

.tt-popover__body {
	padding: 1.5em;
	border: 1px solid #36a9e1;
}

.tt-popover__icon {
	width: 36px;
	height: 36px;
	border-radius: 100%;
	vertical-align: middle;
	margin-right: 1em;
}

.popover-toggler {
	position: relative;
}

.hours-popover {
	width: 14em;

	input {
		text-align: right;
	}
}
