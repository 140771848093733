.open .top-notifyer {
    display: grid;
}

.top-notifyer {
    max-width: 400px;
    width: 400px;
    left: -200px;

    grid-template-columns: 1fr;

    &-header {
        width: 100%;
        text-align: right;
        padding: 0 1em;

        &__clear-all {
            border-bottom: 1px dashed;

            &:hover {
                border-bottom: 1px solid;
                text-decoration: none;
            }
        }
    }

    .loading-view {
        text-align: center;
    }
}

.notifyer__empty {
    padding: 1em;
}

.top-notification {
    padding: 1em;
    margin: 0;
    border-top: 1px dotted lightgray;

    display: flex;
    align-items: stretch;

    &__remove {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__remove-link {
        padding: 0.75em;
    }
}

.top-notifyer-digit {
    color: red;
}

.notifyer-btn {
    font-size: 120%;
    position: relative;
    top: 2px;
}

@media (max-width: 500px) {
    .dropdown .dropdown-menu.top-notifyer {
        width: 100vw;
        position: absolute;
        left: auto;
        right: -69px;
        top: 30px;
    }
}
