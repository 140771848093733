.org-chart {
  margin-left: 20px;
}

.node-container {
  display: flex;
  flex-direction: column;
  opacity: 1;
  padding: 10px 0 0;
  position: relative;

  &.collapsed {
    min-height: 110px;
  }

  .node-controls {
    order: 2;
  }

  .children {
    overflow: hidden;
    order: 3;
  }
}

.h-line {
  left: -34px;
  bottom: 50%;
  width: 34px;
}

.v-line {
  bottom: 50%;
  left: -34px;
  width: 10px;
}

.node {
  position: relative;

  .h-line {
    position: absolute;
    height: 0;
    border-bottom: 1px solid gray;
  }

  .v-line {
    width: 0;
    position: absolute;
    height: 999999px;
    border-left: 1px solid gray;
  }
}

.children {
  padding-left: 64px;
  position: relative;
  min-height: 27px;

  &:empty {
    display: none;

    & + .node-controls {
      display: none;
    }
  }
}
.node-container.collapsed > .children {
  display: none;
}

.node-controls {
  height: 0;
  overflow: visible;
  position: relative;

  .v-line {
    border-left: 1px solid gray;
    height: 14px;
    left: 30px;
    position: absolute;
    top: 0;
  }

  .show-children {
    position: absolute;
    top: 10px;
    line-height: 14px;
    height: 14px;
    left: 24px;
    z-index: 20;
    cursor: pointer;
    background: #fff;

    &:before {
      user-select: none;
      user-drag: none;
      content: "\f146";
      font: 14px FontAwesome;
      line-height: 1;
    }
  }
}
.node-container.collapsed > .node-controls .show-children:before {
  content: "\f0fe";
}

.org-chart > .node-container > .node {
  .h-line, .v-line {
    display: none;
  }
}

.node-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.employee-wrapper {
  border-radius: 5px;
  width: 330px;
  padding: 8px;
  border: 1px solid gray;
  background: #f5f5f5;
  display: block;
  position: relative;
  flex: 0 0 auto;
}

.node-edit-toggle {
  position: absolute;
  right: 5px;
  top: 2px;
  cursor: pointer;
  color: #bcbcbc;

  &:hover {
    color: #222222;
  }
}

.content-container {
  float: left;
  width: 250px;
  padding-left: 7px;
  overflow: hidden;

  .employee-name {
    font-weight: bold;
    line-height: 19px;
  }
}

.img-container {
  float: left;
  border: 1px solid #e9e9e9;

  img {
    vertical-align: middle;
    height: 54px;
    width: 54px;
  }
}

.job-title {
  line-height: 16px;
}

.node-edit {
  display: flex;
  flex: 1 0 200px;
}

.edit-fields {
  flex: 1 1 200px;

  margin: 0 5px;

  label {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.edit-actions {
  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .btn {
    margin: 0;
  }
  .btn + .btn {
    margin-top: .1em;
  }
}

.errorlist {
  margin: .3em 0;
  border: 1px solid $red;
  background-color: tint($red, 72%);
  border-radius: 5px;
  padding-bottom: 7px;
  padding-top: 7px;
}

.node-row-editmode {
  background: #ececec;
  padding: .2em 2em;
  margin: 0 0 0 -2em;
}
