.rating {
  color: #ccc;

  .icon-star {
    margin: 0 .25em;
  }

  &__item {

  }

  &__selected {
    color: $blue;

    &.approved {
      color: gold;
    }
  }
}