.spin {
  @include circle(32px);
  @include spin;

  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
  border-top-color: $blue;
  border-right-color: $blue;

  &_size {
    &_xs {
      @include square(16px);
    }

    &_s {
      @include square(24px);
    }

    &_l {
      @include square(48px);
    }

    &_xl {
      @include square(64px);
    }
  }
}