/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/dt-1.10.10,b-1.1.0,b-colvis-1.1.0,fc-3.2.0,fh-3.1.0,r-2.0.0,sc-1.4.0
 *
 * Included libraries:
 *   DataTables 1.10.10, Buttons 1.1.0, Column visibility 1.1.0, FixedColumns 3.2.0, FixedHeader 3.1.0, Responsive 2.0.0, Scroller 1.4.0
 */

.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: collapse;
  border-spacing: 0
}

.dataTable th,
.dataTable td {
  box-sizing: content-box;
}

.dataTable thead th,
.dataTable thead td {
  font-size: 13px;
  height: 33px;
  padding: 7px 24px 14px 12px;
  vertical-align: bottom;
  color: $gray;
  box-shadow: 0 -1px 0 $border-color inset;

  &:hover {
    box-shadow: 0 -2px 0 $border-color inset;
  }

  &:first-child {
    padding-left: 24px;
  }
}

.dataTable thead th:active,
.dataTable thead td:active {
  outline: none
}

.dataTable tfoot th,
.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid $border-color;
}

.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc {
  cursor: pointer;
}

.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc {
  color: $font-color;

  &,
  &:hover {
    box-shadow: 0 -2px 0 $orange inset;
  }
}

.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right
}

.dataTable thead .sorting {
  background-image: url("../../vendor/css/DataTables-1.10.10/images/sort_both.png")
}

.dataTable thead .sorting_asc {
  background-image: url("../../vendor/css/DataTables-1.10.10/images/sort_asc.png")
}

.dataTable thead .sorting_desc {
  background-image: url("../../vendor/css/DataTables-1.10.10/images/sort_desc.png")
}

.dataTable thead .sorting_asc_disabled {
  background-image: url("../../vendor/css/DataTables-1.10.10/images/sort_asc_disabled.png")
}

.dataTable thead .sorting_desc_disabled {
  background-image: url("../../vendor/css/DataTables-1.10.10/images/sort_desc_disabled.png")
}

.dataTable tbody tr {
  background-color: white;
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }
}

.dataTable tbody tr.selected {
  background-color: #B0BED9
}

.dataTable tbody th,
.dataTable tbody td {
  font-size: 13px;
  padding: 9px 12px;

  &:first-child {
    padding-left: 24px;
  }
}

.dataTable.row-border tbody th,
.dataTable.row-border tbody td,
.dataTable.display tbody th,
.dataTable.display tbody td {
  border-top: 1px solid #ddd
}

.dataTable.row-border tbody tr:first-child th,
.dataTable.row-border tbody tr:first-child td,
.dataTable.display tbody tr:first-child th,
.dataTable.display tbody tr:first-child td {
  border-top: none
}

.dataTable.cell-border tbody th,
.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd
}

.dataTable.cell-border tbody tr th:first-child,
.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd
}

.dataTable.cell-border tbody tr:first-child th,
.dataTable.cell-border tbody tr:first-child td {
  border-top: none
}

.dataTable.stripe tbody tr.odd,
.dataTable.display tbody tr.odd {
  background-color: #f9f9f9
}

.dataTable.stripe tbody tr.odd.selected,
.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4
}

.dataTable.hover tbody tr:hover,
.dataTable.display tbody tr:hover {
  background-color: tint($smoke, 50%);
}

.dataTable.hover tbody tr:hover.selected,
.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1
}

.dataTable.order-column tbody tr>.sorting_1,
.dataTable.order-column tbody tr>.sorting_2,
.dataTable.order-column tbody tr>.sorting_3,
.dataTable.display tbody tr>.sorting_1,
.dataTable.display tbody tr>.sorting_2,
.dataTable.display tbody tr>.sorting_3 {
  background-color: #fafafa
}

.dataTable.order-column tbody tr.selected>.sorting_1,
.dataTable.order-column tbody tr.selected>.sorting_2,
.dataTable.order-column tbody tr.selected>.sorting_3,
.dataTable.display tbody tr.selected>.sorting_1,
.dataTable.display tbody tr.selected>.sorting_2,
.dataTable.display tbody tr.selected>.sorting_3 {
  background-color: #acbad5
}

.dataTable.display tbody tr.odd>.sorting_1,
.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
  background-color: #f1f1f1
}

.dataTable.display tbody tr.odd>.sorting_2,
.dataTable.order-column.stripe tbody tr.odd>.sorting_2 {
  background-color: #f3f3f3
}

.dataTable.display tbody tr.odd>.sorting_3,
.dataTable.order-column.stripe tbody tr.odd>.sorting_3 {
  background-color: whitesmoke
}

.dataTable.display tbody tr.odd.selected>.sorting_1,
.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
  background-color: #a6b4cd
}

.dataTable.display tbody tr.odd.selected>.sorting_2,
.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_2 {
  background-color: #a8b5cf
}

.dataTable.display tbody tr.odd.selected>.sorting_3,
.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_3 {
  background-color: #a9b7d1
}

.dataTable.display tbody tr.even>.sorting_1,
.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
  background-color: #fafafa
}

.dataTable.display tbody tr.even>.sorting_2,
.dataTable.order-column.stripe tbody tr.even>.sorting_2 {
  background-color: #fcfcfc
}

.dataTable.display tbody tr.even>.sorting_3,
.dataTable.order-column.stripe tbody tr.even>.sorting_3 {
  background-color: #fefefe
}

.dataTable.display tbody tr.even.selected>.sorting_1,
.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1 {
  background-color: #acbad5
}

.dataTable.display tbody tr.even.selected>.sorting_2,
.dataTable.order-column.stripe tbody tr.even.selected>.sorting_2 {
  background-color: #aebcd6
}

.dataTable.display tbody tr.even.selected>.sorting_3,
.dataTable.order-column.stripe tbody tr.even.selected>.sorting_3 {
  background-color: #afbdd8
}

.dataTable.display tbody tr:hover>.sorting_1,
.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
  background-color: #eaeaea
}

.dataTable.display tbody tr:hover>.sorting_2,
.dataTable.order-column.hover tbody tr:hover>.sorting_2 {
  background-color: #ececec
}

.dataTable.display tbody tr:hover>.sorting_3,
.dataTable.order-column.hover tbody tr:hover>.sorting_3 {
  background-color: #efefef
}

.dataTable.display tbody tr:hover.selected>.sorting_1,
.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
  background-color: #a2aec7
}

.dataTable.display tbody tr:hover.selected>.sorting_2,
.dataTable.order-column.hover tbody tr:hover.selected>.sorting_2 {
  background-color: #a3b0c9
}

.dataTable.display tbody tr:hover.selected>.sorting_3,
.dataTable.order-column.hover tbody tr:hover.selected>.sorting_3 {
  background-color: #a5b2cb
}

.dataTable.no-footer {
  border-bottom: 1px solid $border-color;
}

.dataTable.nowrap th,
.dataTable.nowrap td {
  white-space: nowrap
}

.dataTable.compact thead th,
.dataTable.compact thead td {
  padding: 4px 17px 4px 4px
}

.dataTable.compact tfoot th,
.dataTable.compact tfoot td {
  padding: 4px
}

.dataTable.compact tbody th,
.dataTable.compact tbody td {
  padding: 4px
}

.dataTable th.dt-left,
.dataTable td.dt-left {
  text-align: left
}

.dataTable th.dt-center,
.dataTable td.dt-center,
.dataTable td.dataTables_empty {
  text-align: center
}

.dataTable th.dt-right,
.dataTable td.dt-right {
  text-align: right
}

.dataTable th.dt-justify,
.dataTable td.dt-justify {
  text-align: justify
}

.dataTable th.dt-nowrap,
.dataTable td.dt-nowrap {
  white-space: nowrap
}

.dataTable thead th.dt-head-left,
.dataTable thead td.dt-head-left,
.dataTable tfoot th.dt-head-left,
.dataTable tfoot td.dt-head-left {
  text-align: left
}

.dataTable thead th.dt-head-center,
.dataTable thead td.dt-head-center,
.dataTable tfoot th.dt-head-center,
.dataTable tfoot td.dt-head-center {
  text-align: center
}

.dataTable thead th.dt-head-right,
.dataTable thead td.dt-head-right,
.dataTable tfoot th.dt-head-right,
.dataTable tfoot td.dt-head-right {
  text-align: right
}

.dataTable thead th.dt-head-justify,
.dataTable thead td.dt-head-justify,
.dataTable tfoot th.dt-head-justify,
.dataTable tfoot td.dt-head-justify {
  text-align: justify
}

.dataTable thead th.dt-head-nowrap,
.dataTable thead td.dt-head-nowrap,
.dataTable tfoot th.dt-head-nowrap,
.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap
}

.dataTable tbody th.dt-body-left,
.dataTable tbody td.dt-body-left {
  text-align: left
}

.dataTable tbody th.dt-body-center,
.dataTable tbody td.dt-body-center {
  text-align: center
}

.dataTable tbody th.dt-body-right,
.dataTable tbody td.dt-body-right {
  text-align: right
}

.dataTable tbody th.dt-body-justify,
.dataTable tbody td.dt-body-justify {
  text-align: justify
}

.dataTable tbody th.dt-body-nowrap,
.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap
}

.dataTables_wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  position: relative;
  clear: both;
}

.dataTables_wrapper .dataTables_length {
  float: left
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding: 10px 20px;
  background: #f5f5f5;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  border: 1px solid transparent;
  border-radius: 2px
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  border: 1px solid #979797;
  background-color: white;
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%)
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid $border-color;
  background-color: #585858;
  background: linear-gradient(to bottom, #585858 0%, #111 100%)
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%)
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
  display: flex;
  flex: auto;
  flex-direction: column;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td {
  vertical-align: middle
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td>div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid $border-color;
}

.dataTables_scrollHead {
  flex: 0 0 auto;
  box-shadow: 0 -1px 0 $border-color inset;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table,
.dataTables_wrapper.no-footer div.dataTables_scrollBody table {
  border-bottom: none
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em
  }
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em
  }
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21
}

div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3
}

div.dt-button-info>div {
  padding: 1em
}

button.dt-button,
div.dt-button,
a.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  background-color: #e9e9e9;
  background-image: linear-gradient(to bottom, #fff 0%, #e9e9e9 100%);
  user-select: none;
  text-decoration: none;
  outline: none
}

button.dt-button.disabled,
div.dt-button.disabled,
a.dt-button.disabled {
  color: #999;
  border: 1px solid #d0d0d0;
  cursor: default;
  background-color: #f9f9f9;
  background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%);
}

button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button.active:not(.disabled),
a.dt-button:active:not(.disabled),
a.dt-button.active:not(.disabled) {
  background-color: #e2e2e2;
  background-image: linear-gradient(to bottom, #f3f3f3 0%, #e2e2e2 100%);
  box-shadow: inset 1px 1px 3px #999999
}

button.dt-button:active:not(.disabled):hover:not(.disabled),
button.dt-button.active:not(.disabled):hover:not(.disabled),
div.dt-button:active:not(.disabled):hover:not(.disabled),
div.dt-button.active:not(.disabled):hover:not(.disabled),
a.dt-button:active:not(.disabled):hover:not(.disabled),
a.dt-button.active:not(.disabled):hover:not(.disabled) {
  box-shadow: inset 1px 1px 3px #999999;
  background-color: #cccccc;
  background-image: linear-gradient(to bottom, #eaeaea 0%, #ccc 100%);
}

button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  text-decoration: none
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  border: 1px solid #666;
  background-color: #e0e0e0;
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #e0e0e0 100%);
}

button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled) {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  outline: none;
  background-color: #79ace9;
  background-image: linear-gradient(to bottom, #bddef4 0%, #79ace9 100%);
}

.dt-button embed {
  outline: none
}

div.dt-buttons {
  position: relative;
  float: left
}

div.dt-buttons.buttons-right {
  float: right
}

div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  margin-top: 3px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  z-index: 2002;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px
}

div.dt-button-collection button.dt-button,
div.dt-button-collection div.dt-button,
div.dt-button-collection a.dt-button {
  position: relative;
  left: 0;
  right: 0;
  display: block;
  float: none;
  margin-bottom: 4px;
  margin-right: 0
}

div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection a.dt-button:active:not(.disabled),
div.dt-button-collection a.dt-button.active:not(.disabled) {
  background-color: #dadada;
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
  box-shadow: inset 1px 1px 3px #666
}

div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0
}

div.dt-button-collection.fixed.two-column {
  margin-left: -150px
}

div.dt-button-collection.fixed.three-column {
  margin-left: -225px
}

div.dt-button-collection.fixed.four-column {
  margin-left: -300px
}

div.dt-button-collection>* {
  -webkit-column-break-inside: avoid;
  break-inside: avoid
}

div.dt-button-collection.two-column {
  width: 300px;
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2
}

div.dt-button-collection.three-column {
  width: 450px;
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3
}

div.dt-button-collection.four-column {
  width: 600px;
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 2001
}

@media screen and (max-width: 640px) {
  div.dt-buttons {
    float: none !important;
    text-align: center
  }
}

table.DTFC_Cloned thead,
table.DTFC_Cloned tfoot {
  background-color: white
}

div.DTFC_Blocker {
  background-color: white
}

div.DTFC_LeftWrapper .dataTable,
div.DTFC_RightWrapper .dataTable {
  margin-bottom: 0;
  z-index: 2
}

div.DTFC_LeftWrapper .dataTable.no-footer,
div.DTFC_RightWrapper .dataTable.no-footer {
  border-bottom: none
}

table.fixedHeader-floating {
  position: fixed !important;
  background-color: white
}

table.fixedHeader-floating.no-footer {
  border-bottom-width: 0
}

table.fixedHeader-locked {
  position: absolute !important;
  background-color: white
}

@media print {
  table.fixedHeader-floating {
    display: none
  }
}

.dataTable.dtr-inline.collapsed>tbody>tr>td.child,
.dataTable.dtr-inline.collapsed>tbody>tr>th.child,
.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty {
  cursor: default !important
}

.dataTable.dtr-inline.collapsed>tbody>tr>td.child:before,
.dataTable.dtr-inline.collapsed>tbody>tr>th.child:before,
.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty:before {
  display: none !important
}

.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child,
.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer
}

.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before,
.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before {
  top: 8px;
  left: 4px;
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 16px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  font-family: 'Courier New', Courier, monospace;
  text-indent: 4px;
  line-height: 16px;
  content: '+';
  background-color: #31b131
}

.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
  content: '-';
  background-color: #d33333
}

.dataTable.dtr-inline.collapsed>tbody>tr.child td:before {
  display: none
}

.dataTable.dtr-inline.collapsed.compact>tbody>tr>td:first-child,
.dataTable.dtr-inline.collapsed.compact>tbody>tr>th:first-child {
  padding-left: 27px
}

.dataTable.dtr-inline.collapsed.compact>tbody>tr>td:first-child:before,
.dataTable.dtr-inline.collapsed.compact>tbody>tr>th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px
}

.dataTable.dtr-column>tbody>tr>td.control,
.dataTable.dtr-column>tbody>tr>th.control {
  position: relative;
  cursor: pointer
}

.dataTable.dtr-column>tbody>tr>td.control:before,
.dataTable.dtr-column>tbody>tr>th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 16px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  font-family: 'Courier New', Courier, monospace;
  text-indent: 4px;
  line-height: 16px;
  content: '+';
  background-color: #31b131
}

.dataTable.dtr-column>tbody>tr.parent td.control:before,
.dataTable.dtr-column>tbody>tr.parent th.control:before {
  content: '-';
  background-color: #d33333
}

.dataTable>tbody>tr.child {
  padding: 0.5em 1em
}

.dataTable>tbody>tr.child:hover {
  background: transparent !important
}

.dataTable>tbody>tr.child ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0
}

.dataTable>tbody>tr.child ul li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0
}

.dataTable>tbody>tr.child ul li:first-child {
  padding-top: 0
}

.dataTable>tbody>tr.child ul li:last-child {
  border-bottom: none
}

.dataTable>tbody>tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6)
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6)
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%
  }
}

div.DTS tbody th,
div.DTS tbody td {
  white-space: nowrap
}

div.DTS tbody tr.even {
  background-color: white
}

div.DTS div.DTS_Loading {
  z-index: 1
}

div.DTS div.dataTables_scrollBody {
  background: repeating-linear-gradient(45deg, #edeeff, #edeeff 10px, #fff 10px, #fff 20px)
}

div.DTS div.dataTables_scrollBody table {
  z-index: 2
}

div.DTS div.dataTables_paginate {
  display: none
}