.projects-list {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
	list-style: none;
	margin: 0;
	padding: 24px;

	@include breakpoint($mobile) {
		grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
	}

	@include breakpoint($tablet) {
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
	}

	@include breakpoint($desktop-hd) {
		grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
	}

	@include breakpoint($desktop-uxga) {
		grid-template-columns: repeat(auto-fill, minmax(100% / 6, 1fr));
	}

	@include breakpoint($desktop-qhd) {
		grid-template-columns: repeat(auto-fill, minmax(100% / 8, 1fr));
	}

	.loader-wrapper {
		position: absolute;
		width: 100%;
	}
}

.profile-projects {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
	list-style: none;
	margin: 0;
	padding: 8px 0;

	@include breakpoint($phablet) {
		grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
	}

	@include breakpoint($tablet) {
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
	}

	@include breakpoint($desktop-hd) {
		grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
	}

	@include breakpoint($desktop-uxga) {
		grid-template-columns: repeat(auto-fill, minmax(100% / 5, 1fr));
	}

	@include breakpoint($desktop-qhd) {
		grid-template-columns: repeat(auto-fill, minmax(100% / 6, 1fr));
	}
}

.projects-list__new {
	display: inline-flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 15px;

	.icon {
		font-size: 24px;
		width: 72px;
		height: 72px;
		text-align: center;
		line-height: 66px;
		border: 1px solid rgba(black, .1);
		border-radius: 50%;
		background: rgba(white, .75);
		transition: all .255s;
	}

	&:hover {
		.icon {
			transform: scale(1.5);
		}
	}
}

.project-card {
	display: inline-flex;
	flex-direction: column;
	position: relative;
	margin: 0;
	background: white;
    border-radius: 3px;

	&__customer {
		font-size: 11px;
		display: block;
		margin-bottom: 4px;
		color: $gray;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	&__title {
		font-size: 18px;
		line-height: 1.2;
		margin: 0 0 4px;

		.link {
			@include link($font-color, $font-color);
		}
	}

	&__heading {
		margin-bottom: 8px;
	}

	&__dates {
		padding-top: 4px;

		&:empty {
			display: none;
		}
	}

	&__skills {
		display: flex;
		flex-flow: row wrap;

		.label {
			margin: 1px 1px 0 0;
		}
	}

	&__summary {
		flex: auto;
		margin-bottom: 16px;
	}

	&__team {
		display: flex;
		margin-top: 15px;
	}

	&__team-item {
		display: inline-block;
		margin-right: -3px;

		.avatar {
			box-shadow: 0 0 0 2px white;
		}
	}

	&__team-more {
		@include circle(32px);
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		line-height: 32px;
		background: $blue;
		color: white;
		box-shadow: 0 0 0 2px white;
	}

	&__roles {
		flex: auto;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
}

.project {
	border: 1px rgba(0, 0, 0, 0.1) solid;

	&:not(:last-child) {
			margin-bottom: 20px;
	}

	.header-buttons {
			float: right;
	}

	&-description {
			white-space: nowrap;
			overflow:  hidden;
			text-overflow: ellipsis;
			max-width: 800px;
	}
}

.projects-item-name {
	font-weight: bold;
}

.projects-item-techs {
	margin-bottom: .5em;
}

.projects-item-members {

	.userpic {
		display: inline-block;
		width: 18px;
		height: 18px;
		margin: 0 3px;
		border-radius: 100%;
	}
}
