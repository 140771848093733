.custom-field-text-error {
  display: block;
  margin-bottom: 1em;
  color: $red;
}

.section-type {
  margin-right: 1em;
}

.custom-field-settings {
  padding: 1em;
  margin-bottom: 3em;

  .sections-container {

    margin-left: 1em;

    .custom-fields-header {
      display: inline-block;
      margin: 1em 0;
    }

    .add-section-btn {
      float: right;
      margin-top: 1em;
    }

    .section-container {
      padding-bottom: 1em;
      margin-bottom: 1em;
      border: 1px solid rgb(236, 236, 236);

      .section-header-container {
        background-color: rgb(236, 236, 236);
        padding: .5em;

        .section-actions {
          display: inline-block;
          float: left;
          padding-top: .2em;

          .edit-btn, .delete-btn {
            display: inline-block;
            font-size: 1.2em;
            margin: 0 0 0 .3em;
            color: lighten($gray, 20%);

            &:hover {
              color: $gray;
              cursor: pointer;
            }
          }
        }

        .section-header {
          .name {
            display: inline-block;
            font-size: 1.2em;
            padding-left: .5em;
          }

          .collapse-section-btn {
            display: inline-block;
            float: right;
            margin: 0 .5em;
            padding: 0 .5em;
            background-color: lighten($gray, 20%);
            border-radius: .2em;

            &:hover {
              background-color: $gray;
              cursor: pointer;
            }

            .collapse-section-icon {
              transition: transform 0.5s;
              display: inline-block;
              font-size: 1.2em;
              color: rgb(236, 236, 236);

              transform: rotate(0deg);

              &.collapsed {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
    }
  }
}

.field-groups-container {
  padding: 10px 10px 0 10px;

  .field-group-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .field-group {
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 8px;
      border: 1px solid $light-gray;
      background: #f5f5f5;
      display: inline-block;
      position: relative;
      flex: 0 0 auto;

      .field-group-name {
        text-align: center;
        font-size: 1.1em;
      }

      .field-group-actions {
        position: absolute;
        right: 5px;
        top: 2px;

        .edit-btn, .delete-btn {
          display: inline-block;
          font-size: 1.2em;
          margin: 0 0 0 .1em;
          color: lighten($gray, 15%);

          &:hover {
            color: $gray;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.fields-container {
  .field-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .field {
      display: inline-block;
      margin: 20px 5px 10px 5px;
      min-width: 100px;
      min-height: 70px;
      padding: 5px;
      background-color: lighten($gray, 40%);
      border-radius: 5px;

      &:hover {
        background-color: lighten($gray, 30%);
        cursor: pointer;
      }

      .field-name {
        text-align: center;
      }

      .field-type {
        text-align: center;
      }
    }
  }
}

.add-field-btn {
  display: inline-block;
  margin: 27px 5px 5px 5px;
  width: 57px;
  height: 57px;
  border-radius: 10px;
  background-color: lighten($gray, 20%);
  color: lighten($gray, 45%);
  line-height: 1.3;
  font-size: 45px;
  text-align: center;

  &:hover {
    background-color: lighten($gray, 10%);
    cursor: pointer;
  }
}

.select-option-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .remove-select-option-link {
    padding-left: 3px;
    margin-top: 6px;
  }
}

.allow-multiple-addition-checkbox {
  margin-left: 5px !important;
}

.corporate-sections {
  width: 40em;
}

.profile-section {
  .field-group {
    margin-bottom: 1em;
    padding-top: .5em;
    border-top: 1px solid #dddddd;

    .field-group-name {
      margin: 0 0 .5em .5em;
    }

    .add-field-group-link {
      margin-left: 8px;
    }

    .field-group-row {
      .remove-field-group-link {
        color: $light-gray;
        float: right;
        margin-right: -10px;
        margin-top: -40px;

        &:hover {
          color: $gray;
        }
      }

      .field-list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .field {
          padding: 8px;
          flex-grow: 1;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .custom-checkbox {
            padding-left: 4em;
          }

          .field-label {
            font-weight: normal;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
