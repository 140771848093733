.modal-content {
	box-shadow: 0 5px 15px rgba(0,0,0,0.25);
	display: block;
	border-color: transparent;
	border-radius: 0;
}

.modal-header {
	background: $blue;
	color: #fff;
	border: none;
}

.modal-backdrop {
	background: #fff;

	&.in {
		opacity: .75;
	}
}

.left-actions {
    float: left;
}