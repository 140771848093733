.access-pad {
  @include shadow-box;
  width: 100%;
  min-width: 320px;
  max-width: 720px;
  background: white;
  padding: 20px;
  border-radius: 4px;

  @include breakpoint($phablet) {
    display: flex;
    padding: 20px;
  }

  @include breakpoint($tablet) {
    padding: 40px;
  }

  &__display {
    flex: auto;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 150px;
    margin-bottom: 20px;
    background: #f6f6f6;
    border: 1px solid rgba(black, .05);
    border-radius: 4px;

    &:before {
      @include rect(200%, 100%);
      @include pseudo;
      transform: translate3d(-50%, -30%, 0) rotate(-30deg);
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(black, .03);
    }

    @include breakpoint($phablet) {
      height: auto;
      margin-right: 20px;
      margin-bottom: 0;
    }

    @include breakpoint($tablet) {
      margin-right: 40px;
    }

    & > * {
      flex: auto;
    }
  }

  &__message {
    font-size: 4em;
    font-weight: bold;
    flex: auto;
    padding: 30px 20px;
    line-height: 1;
    text-transform: uppercase;
    vertical-align: middle;
    color: $red;

    @include breakpoint($tablet) {
      font-size: 5em;
      padding: 0;
    }
  }

  &__pass {
    flex: auto;
    transition: color .225s ease-out;
    font-size: 6em;
    font-weight: bold;
    line-height: 1;
    letter-spacing: .1em;

    &_locked {
      color: tint($font-color, 25%);
    }

    @include breakpoint($tablet) {
      font-size: 8em;
    }
  }

  &__keyboard {
    flex: 0 0 auto;

    @include breakpoint($phablet) {
      flex: 0 0 auto;
      width: span(2 of 5);
    }

    @include breakpoint($tablet) {
      width: span(1 of 3);
    }
  }

  &__keys {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__key {
    @include box;
    width: span(1 of 3);
    font-size: 3em;
    font-weight: 600;
    user-select: none;
    border-radius: 2px;
    text-align: center;
    box-shadow: 0 2px 0 rgba(0,0,0,.25);
    position: relative;
    overflow: hidden;
    margin-bottom: gutter();
    padding: 3% 0;
    text-align: center;
    cursor: pointer;
    background: $smoke;

    &:last-child {
      @include pre(1 of 3);
      margin-bottom: 0;
    }

    @include breakpoint($tablet) {
      font-size: 2em;
      padding: 5% 0;

      &:hover {
        box-shadow: 0 2px 0 shade($blue, 25%);
        color: white;
        background: $blue;
      }
    }

    &:active {
      &,
      &:hover {
        box-shadow: none;
        top: 2px;
        color: shade(white, 10%);
        background: shade($blue, 10%);
      }
    }
  }
}