.radio {
  &_type {
    &_button {
      display: inline-block;
      position: relative;
      padding: 0;
      margin: 0;
      min-height: auto;
      vertical-align: middle;

      .btn {
        position: relative;
        border-radius: 0;
        background: rgba(black, .1);
        border: none;

        &:before {
          @include pseudo;
          @include stretch(1px, 1px, 1px, 1px);
          background: white;
          transition: background ease-in-out 0.15s;
        }

        &__text {
          position: relative;
          color: $gray;
          transition: color .225s ease-out;
        }

        &:hover {
          background: rgba(black, .2);
          .btn__text {
            color: $font-color;
          }
        }

        &:active {
          &:before {
            background: $smoke;
          }
        }
      }

      &:first-child {
        .btn {
          border-radius: 2px 0 0 2px;
        }
      }

      &:last-child {
        .btn {
          border-radius: 0 2px 2px 0;

          &:before {
            right: 1px;
          }
        }
      }

      .radio__control {
        position: absolute;
        visibility: hidden;
        z-index: -1;
        margin: 0;
        padding: 0;

        &:checked {
          + .btn {
            background: shade($blue, 15%);
            color: white;

            &:before {
              background: $blue;
              right: 1px;
            }

            .btn__text {
              color: white;
            }
          }
        }
      }
    }
  }
}