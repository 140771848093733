.header-nav {
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $mobile-nav-width;
    margin: 0;
    padding: 10px 0 20px;
    background: #f7f8f9;
    border-right: 1px solid rgba(black, .1);
    transform: translateX(-100%);

    &__item {
      text-transform: uppercase;
      letter-spacing: .75px;

      a {
        display: block;
        padding: 10px 20px;
        text-decoration: none;



        &:hover,
        &:focus,
        &:active {
          color: $blue;
        }
      }

      &.active {
        a {
          &,
          &:hover,
          &:focus,
          &:active {
            color: $blue;
            text-decoration: none;
          }
        }
      }
    }

    @include breakpoint($tablet) {
        flex: auto;
        width: auto;
        top: auto;
        left: auto;
        position: relative;
        padding: 0 24px;
        transform: none;
        background: transparent;
        border: none;

        &__item {
          display: inline-flex;
          margin-right: 20px;

          a {
            padding: 0;
          }
        }
    }
}