/* Some css for the report page is at css/reports/reports.css
 * It overrides some core styles so it doesn't need to be packaged with all the css
 */

 .report-list {
     display: flex;
     flex-flow: row wrap;
     justify-content: center;

     &-item {
        @include with-layout(60 4 split, true) {
            @include span(60);

            @include breakpoint($mobile) {
                @include span(30);
            }

            @include breakpoint($tablet) {
                @include span(20);
            }

            @include breakpoint($desktop-hd) {
                @include span(15);
            }

            @include breakpoint($desktop-uxga) {
                @include span(12);
            }
        }

        &__link {
            &:after {
                @include pseudo;
                @include rect(30%, 1px);

                display: block;
                background: $smoke;
                margin: 1em auto;
            }
        }

        &__icon {
            @extend .fa;
            @extend .icon-file-text-alt;

            font-size: 48px;
            display: block;
            color: $light-gray;
        }
     }
 }

.report-page-body {
    flex: 1;
    overflow-x: hidden;
    position: relative;
    display: flex;
}

.report-sidebar-wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 26em;
    overflow-x: hidden;
    background: white;
    border-left: 1px solid shade($smoke, 10%);

    .loading & {
        display: none;
    }
}

.report-loader {
    display: none;

    .loading & {
        display: block;
    }
}

.grid-wrapper {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .loading & {
        display: none;
    }
}
.grid-header {
    flex: 0 0 auto;
    min-height: 55px;
    padding: 10px 20px;
    border-bottom: 1px solid shade($smoke, 10%);
    background: #f5f5f5;
}

.grid-body {
    flex: auto;
    position: relative;
}

.filter-wrapper {
    padding: 20px;
}
.grouping-wrapper {
    padding: 20px;
    border-top: 1px solid shade($smoke, 10%);
}

.filter-column,
.column-aggregator {
    margin-top: 1em;

    &:first-child {
        margin-top: 0;
    }
}

.reports-toolbar {
    .left-side {
        display: inline-block;
    }

    .right-side {
        float: right;
    }
}
.data-reload-container {
    flex: 1;
    display: inline-block;
    padding-right: .75em;

    .select2-selection__choice {
        display: inline-block !important;
    }
    .select2-container {
        min-width: 12em;
    }
}
.report-date {
    width: 8em;
    height: 34px;
}
.data-reload {
    display: inline-block;
}

.slick-group-toggle {
    margin-right: 5px;
}
