
.slick {
  &-header,
  &-headerrow,
  &-footerrow  {
    width: 100%;
    overflow: hidden;
    border-left: 0px !important;

    &-columns {
      position: relative;
      white-space: nowrap;
      cursor: default;
      overflow: hidden;
    }
  }

  &-viewport {
    background: white;
  }

  &-header {
    border: 0;
    background: white;

    &-sortable {
      cursor: pointer;
    }

    &-columns {
      background: white;
      box-shadow: 0 -1px 0 $light-gray inset;
    }

    &-column {
      font-size: 13px;
      position: relative;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 54px;
      line-height: 48px;
      margin: 0;
      min-width: 60px;
      padding: 7px 24px 7px 12px;
      border: 0;
      border-bottom: 1px solid $light-gray;
      background: white;
      color: $gray;
      transition: border-color .225s ease-out;

      &:first-child {
        padding-left: 24px;
      }

      &-sorted {
        color: $font-color;
        border-width: 2px;
        border-color: $orange;
      }

      &-active,
      &:hover {
        font-weight: inherit;
        border-width: 2px;
        color: $font-color;
      }
    }
  }

  &-cell,
  &-headerrow-column,
  &-footerrow-column {
    font-size: 13px;
    position: absolute;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    z-index: 1;
    padding: 9px 12px;
    margin: 0;
    white-space: nowrap;
    cursor: default;

    &:first-child {
      padding-left: 24px;
    }
  }

  &-sort-indicator {
    @extend .fa;
    @extend .icon-sort;

    position: absolute;
    right: 10px;
    width: 8px;
    height: 5px;
    color: $light-gray;

    &-desc {
      @extend .icon-sort-down;
      color: $gray;
    }

    &-asc {
      @extend .icon-sort-up;
      color: $gray;
    }
  }

  &-row {
    position: absolute;
    border: 0;
    width: 100%;
    border-bottom: 1px solid $light-gray;

    &:hover {
      background: tint($smoke, 50%);
    }
  }

  &-columnpicker {
    display:none;
    position:absolute;
    z-index:20;
    min-width: 200px;
    margin-top: -1px;
    padding: 10px 20px;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.25);
    cursor: default;
    border: 1px solid shade($smoke, 5%);
    background: white;

    li {
      list-style: none;
      margin: .25em 0 0;
      padding: 0;
      background: none;
    }

    label {
      display: block;
      margin: 0;
      padding: .125em 1em .125em 1.25em;
    }

    input {
      margin: 4px .75em 0 0;
    }

    hr {
      margin-top: 0;
    }
  }
}

.slick-headerrow-column, .slick-footerrow-column {
  padding: 4px;
}

.slick-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  cursor: col-resize;
  width: 4px;
  right: 0px;
  top: 0;
  height: 100%;
}

.slick-sortable-placeholder {
  background: $light-gray;
}

.grid-canvas {
  position: relative;
  outline: 0;
}

.slick-footerrow-column {
  border-top-color: silver;
}

.slick-group-toggle {
  display: inline-block;
}

.slick-cell.highlighted {
  background: lightskyblue;
  background: rgba(0, 0, 255, 0.2);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.slick-cell.flashing {
  border: 1px solid red !important;
}

.slick-cell.editable {
  z-index: 11;
  overflow: visible;
  background: white;
  border-color: black;
  border-style: solid;
}

.slick-cell:focus {
  outline: none;
}

.slick-reorder-proxy {
  display: inline-block;
  background: blue;
  opacity: 0.15;
  filter: alpha(opacity = 15);
  cursor: move;
}

.slick-reorder-guide {
  display: inline-block;
  height: 2px;
  background: blue;
  opacity: 0.7;
  filter: alpha(opacity = 70);
}

.slick-selection {
  z-index: 10;
  position: absolute;
  border: 2px dashed black;
}
