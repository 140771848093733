.tt-tooltip {
	box-shadow: 0 2px 10px rgba(0,0,0,.15);
	position: absolute;
	z-index: 10;
	max-width: 32em;
	margin: -12px 0 0 -.75em;
	background: #fff;

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		overflow: hidden;
		top: 100%;
		left: 1em;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0;
	}


	&:before {
		border-color: #36a9e1 transparent transparent;
	}

	&:after {
		border-color: #fff transparent transparent;
		margin: -1px 0 0;
	}

	&.tt-tooltip_dir_right {
		&:before,
		&:after {
			left: auto;
			right: 1em;
		}
	}

	.close {
		margin: .25em;
	}
}

.tt-tooltip__title {
	padding: .5em 1em;
	background: #36a9e1;
	color: #fff;
	text-transform: uppercase;
}

.tt-tooltip__body {
	padding: 1em;
	border: 1px solid #36a9e1;
}

.tt-tooltip__icon {
	width: 36px;
	height: 36px;
	border-radius: 100%;
	vertical-align: middle;
	margin-right: 1em;
}

.tooltip-toggler {
	position: relative;
}
