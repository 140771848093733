.table {
  > thead {
    > tr {
      > th {
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        color: $gray;
        letter-spacing: .75px;
      }
    }
  }
}