.page {
  font-family: $default-font;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  line-height: 1.5;
  color: $font-color;
  background: white;
  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: column;

  &__inner {
    flex: auto;

    display: flex;
    flex-direction: column;

    .page_menu_visible & {
      transform: translateX($mobile-nav-width);
    }

    @include breakpoint($tablet) {
      .page_menu_visible & {
        transform: none;
      }
    }
  }

  &-header {
    flex: 0 0 auto;
    margin: 0;
    padding: 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: white;

    h1 {
      font-size: 24px;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 1.3;

      .fa {
          margin-right: .25em;
      }
    }

    @include breakpoint($tablet) {
      padding: 0 24px;
    }

    &:empty {
      display: none;
    }
  }

  &-title-mobile {
    @include breakpoint($tablet) {
      display: none;
    }

    h1 {
      font-size: 26px;
      margin: 0 15px 0 0;
    }

  }

  &-body {
    padding: 15px;

    @include breakpoint($tablet) {
      padding: 20px;
    }
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $default-font;
}
