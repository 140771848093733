$title-color: #057D9F;
$title-element-color: #39AECF;

.overall-skills {
  &_state {
    &_empty {
      .overall-skills__no-results {
        display: block;
      }
    }
  }

  &__no-results {
    display: none;
  }
}

.skill-info {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  .table {
    display: none;
    margin: 0 !important;
    border: 1px solid $green;
    border-top: none;

    td {
      border-width: 0 0 1px;
      border-style: none none solid;
      border-color: $green;
      padding: 1em;

      &.skill-attitude {
        .attitude {
          display: inline-block;
        }
      }
    }
  }

  &.expanded {
    .table {
      display: table;
    }
  }

  .skill-attitude {
    text-align: right;
  }
}

.table-title {
  transition: background .3s;

  position: relative;
  display: block;
  height: 4em;
  padding: .25em 3.5em .25em 1em;
  color: white;
  background-color: tint($green, 25%);

  &:hover,
  .skill-info.expanded & {
    background: $green;

    .show-table {
      background: shade($green, 10%);
    }
  }

  .show-table {
    transition: background .3s;
    @include square(4em);
    @include user-select;

    top: 0;
    right: 0;
    position: absolute;
    line-height: 4em;
    background-color: shade(tint($green, 25%), 10%);
    cursor: pointer;
    text-align: center;

    .icon-chevron-up,
    .icon-chevron-down {
      opacity: .8;
    }

    &:hover {
      .icon-chevron-up,
      .icon-chevron-down {
        opacity: 1;
      }
    }
  }

  &-skill-name {
    font-size: 16px;
    display: block;
    margin-bottom: .125em;
  }

  &-emps-amount {
    float: left;
    width: 7em;
    border-radius: 7px;
  }

  &-avr-lvl {
    float: left;
    text-align: center;
    width: 9em;
    margin-left: .1em;
    margin-right: .1em;
    padding: .1em;
    border-radius: 7px;
  }
}

#filter {
  margin: 1em 0;
  width: 100%;
}

#topSkillsBarPlot {
  display: block;
  height: 20em;
  width: 96%;
  margin: 2%;
}


#skillEmpsDynamicPlot,
#skillLvlDynamicPlot {
  display: block;
  height: 30em;
  margin-bottom: 1.5em;
}

#selectedSkills {
  padding: 0 0 1em 3.5em;
}

.skill-div {
  display: block;
  padding: .3em .5em;
  height: 2em;
  width: 12em;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.close-cross {
  float: right;
  padding-left: .25em;

  .icon-remove {
    vertical-align: middle;
  }
}
