.standalone-message {
  &-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #DFF1FF url('../../img/bg.jpg');
    background-size: cover;
    text-align: center;
    align-content: center;
    justify-content: center;
  }

  &-box {
    @include box($valign: middle);
    box-shadow: 0 2px 0 rgba(black, .2);
    align-self: center;
    width: 30em;
    max-width: 100%;
    text-align: left;
    border-radius: 3px;

    .box-content {
      a {
        text-decoration: underline;
      }

      background: rgba(white, .35);
      text-align: center;
      font-size: 16px;
    }

    .logo {
      font-size: 1.75em;
      display: block;
      text-align: center;
      padding: .25em;
    }
  }
}
