$icon-sizes: (
  l: 32px,
  m: 24px,
  s: 16px,
);

.icon {
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  *margin-right: .3em;

  &:before {
    text-decoration: inherit;
    display: inline-block;
    speak: none;
  }

  &_theme {
    &_blue {
      color: $blue;
    }
  }

  @each $key, $value in $icon-sizes {
    &_size_#{$key} {
      font-size: $value;
    }
  }
}

.icon-remove:before {
  content: "×";
  font-family: $default-font;
}

.glyphicons-icon {
  width: 32px;
  height: 24px;
  vertical-align: middle;
  line-height: 24px;

  &.skype {
    background-position: -386px -1969px;
  }

  &.envelope {
    background-position: -2px -50px;
  }

  &.facebook {
    background-position: -2px -1969px;
  }

  &.globe {
    background-position: -2px -1633px;
  }
}

.icon-chevron_expand {
  padding-left: .25em;
  font-size: .875em;
}

.preloader {
  padding: 2em;
  text-align: center;
}

.attitude {
  vertical-align: middle;
}

.icon-question-sign {
  cursor: pointer;
}