.dynamic-menu {
  font-size: 16px;
  display: flex;
  height: auto;
  margin: 0 0 -1px;
  padding: 0;
  list-style: none;
  align-items: center;
  justify-content: center;

  > li > a {
    display: block;
    padding: 15px 0;
    color: $gray;

    &:hover,
    &:focus {
      color: $font-color;
      background: transparent;
    }

  }

  &__item {
    margin: 0 15px 0 0;
    border-bottom: 2px solid transparent;

    a {
      color: $gray;
      text-decoration: none;
    }

    &.active {
      border-bottom-color: $blue;

      a {
        pointer-events: none;
        cursor: default;
        color: $font-color;
      }
    }

    @include breakpoint(992px) {
      margin: 0 25px 0 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}


