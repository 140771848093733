.policies {

  .policies-list {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 600px;
    margin: 0 auto;
  }

  .policies-item {
    display: flex;
    flex-direction: column;

    background: white;
    border-radius: 3px;
    position: relative;
    padding: 20px 30px;
    margin-bottom: 10px;

    font-size: 1.5rem;

    .policy-name {
      font-weight: bold;
      font-size: 1.25em;
      margin-bottom: 1em;
    }

    .policy-description {
      margin-bottom: 1em;
    }

    .policy-link {
      color: $blue;
    }

  }

  .policies-title {
    font-size: 3rem;
    text-align: center;
    margin: 1rem 0;
  }

  .policies-subtitle {
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem;
  }

  .policies-agree {
    text-align: center;
    margin-top: 10px;

    &__check {
      display: block;
    }
  }
}