.profile {
  display: flex;
  flex-direction: column;

  &-details {
    @include shadow-box;
    flex: 0 0 auto;
    margin: 0 0 20px;
    background: white;
    border-radius: 4px;

    &__section {
      position: relative;
      padding: 25px 30px;
      border-bottom: 1px solid rgba(black, .1);

      &:last-child {
        border: none;
      }

      p {
        margin-bottom: .5em;
      }
    }

    &__title {
        font-size: 12px;
        margin: 0 0 15px;
        text-transform: uppercase;
        letter-spacing: .75px;
    }
  }

  &-content {
    flex: auto;
  }

  &__description {
     margin: 10px 0 25px;
  }

  &__name {
    font-size: 22px;
    margin: 0;
    text-align: center;
  }

  &__photo {
    margin-top: 10px;
    text-align: center;
  }

  &__actions {
    margin-top: 15px;
    text-align: center;

    .btn {
      margin: 4px;
    }
  }

  &__job {
    margin-bottom: 5px;
    text-align: center;
  }

  &__reports_to {
    margin-bottom: 5px;
    text-align: center;
    a {
        color: $blue;
    }
  }

  &__status {
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: .75px;
    background: lighten($gray, 35%);
    color: white;
  }

  &__location {
    color: $gray;
    text-align: center;
    margin-bottom: 15px;

    [class*="icon-"] {
      margin-right: 5px;
    }
  }

  &__more-toggle {
    display: block;
    margin-top: 20px;
    text-align: center;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }

    .icon-angle-down {
      display: inline-block;
      transform: rotate(-180deg);
    }

    .more-less-label:before {
      content: attr(data-less);
    }

    &.collapsed {
      .icon-angle-down {
        transform: rotate(0deg);
      }
      .more-less-label:before {
        content: attr(data-more);
      }
    }
  }

  @include breakpoint($desktop) {
    align-items: flex-start;
    flex-direction: row;

    &-details {
      width: 260px;
      margin-right: 20px;

      .collapse {
        display: block;
        height: auto !important;
      }
    }

    .profile__more-toggle {
      display: none;
    }
  }

  @include breakpoint(1180px) {
    &-details {
      width: 320px;
      margin-right: 30px;
    }
  }
}

.personal-data {
  p {
    margin-bottom: .5em;
  }

  a {
    color: $blue;
  }

  .icon {
    margin-right: 10px;
    color: $gray;
  }
}

.icon-angle-down {
    transition: transform 0.5s;
}

.employee-job-title {
    color: $gray;

    .personal-data & {
        margin-bottom: 1.5em;
    }

    .employee-title {
        display: block;
    }
}

.dismiss-employee-modal {
    .dismiss-modal-item {
        margin-bottom: 1em;
    }

    .comment {
        margin-bottom: .5em;
        display: block;
    }

    .dismissal-report-to-label {
        margin-bottom: 5px;
    }

    .input-comment {
        width: 100%;
        max-width: 100%;
    }
}

.employee-name-header {
    display: inline-block;
}

.table-changePhoto{
    a {
        color: #428bca;
    }
}

.suggestion-comment {
    color: #cecece;
}

.work-experience {
    margin-bottom: 1.5em;
}

.level-suggestion {
    padding-left: 1em;
    margin-bottom: .4em;
    font-style: italic;
}

.form_field_error input {
	border-color: #F10F0F;
}

.profile-scrollbox {
	height: 32em;
	overflow: auto;
}

.pagination li span:hover {
    cursor: pointer;
}

.custom-field-block-name {
    font-weight: bold;
}

.btn-give-feedback {
  .icon {
    color: $orange;
  }
}
