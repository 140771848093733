@mixin link($color: $link-color, $hover-color: $link-hover-color) {
  &:link,
  &:visited {
    color: $color;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }

  &:hover {
    text-decoration: none;
    border-color: rgba($hover-color, .5);
  }
}

@mixin pseudo-link($color: $link-color, $hover-color: $link-hover-color) {
  display: inline;
  text-decoration: none;
  cursor: pointer;
  color: $color;
  border-bottom: 1px dotted;

  &:hover {
    text-decoration: none;
    color: $hover-color;
  }
}

.pseudo-link {
  @include pseudo-link();
}

.blue-link {
  color: #1e8fc6;

  &:hover {
    color: #36a9e1;
  }
}

a {
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

.link {
  @include link;
}

.user-link {
  display: inline-flex;
  align-items: center;

  &,
  &:hover {
    text-decoration: none;
  }

  &__text {
    .user-link__img + & {
      margin-left: 10px;
    }
  }

  &__name {
    color: $link-color;
    border-bottom: 1px solid transparent;
  }

  &:hover {
    .user-link__name {
      color: $link-hover-color;
      border-bottom-color: rgba($link-hover-color, .5);
    }
  }
}

.anchor {
  font-weight: normal;
  margin: 0;
}