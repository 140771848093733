.select2 {
  &-container {

    &.select2-container--focus {
      .select2-selection {
        border-color: $blue;
      }
    }
    .select2-selection {
      box-shadow: none;
      transition: border-color $transfast;
      line-height: 1;
      min-height: 34px;
      vertical-align: middle;
      overflow: hidden;
      border-radius: 3px;
      border: 1px solid $border-color;
      background: white;

      input.select2-search__field {
        box-sizing: content-box;
        margin: 0;
        padding: 3px 6px;

        &::-webkit-input-placeholder {
          color: #999;
        }
        &:-ms-input-placeholder {
          color: #999;
        }
        &:-moz-placeholder {
          color: #999;
        }
      }

      &__arrow {
        height: 32px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAlklEQVQ4T+3SMQ5BQRDG8d+7hVLrEhqJ4h1ANCqFE0g0CuVzBJ2SAygkKqfQ6pyCbLISWdYWrxNTznzzn8w3U2kZVct+PwjYY4lLxpseVhg966kHfWwwxDWBdHHEFOccIORrrDHALQo7OGGOwys4d4VxFAdI0ITmBrt0tW9nnGESAdu42ps1pT9Y4B6nf/S1BCg+6h/AA/hvEhFscWXpAAAAAElFTkSuQmCC);
        background-position: center;
        background-repeat: no-repeat;

        b {
          display: none;
        }
      }

      &--multiple {

        .select2-search--inline {
          margin-left: 0;
          margin-top: 3px;
        }

        .select2-selection {

          &__rendered {
            display: block;
            line-height: 1;
            padding: 1px;
          }
          &__choice {
            font-size: 13px;
            box-shadow: none;
            border-radius: 2px;
            line-height: inherit;
            position: relative;
            margin: 1px;
            padding: 6px 24px 5px 6px;
            color: white;
            background: $blue;
            border: none;
          }
          &__clear {
            font-size: 28px;
            font-weight: 300;
            height: 30px;
            margin: -1px 0 0;
          }
          &__choice__remove {
            font-size: 19px;
            font-weight: 300;
            position: absolute;
            top: 50%;
            right: 5px;
            line-height: 20px;
            margin: 0;
            height: 24px;
            transform: translateY(-50%);

            &,
            &:hover {
              color: white;
            }
          }
        }
      }

      &--single {
        .select2-selection {
          &__rendered {
            line-height: 34px;
            padding-left: 10px;
            padding-right: 28px;
          }

          &__arrow {
            top: 0;
            width: 32px;
            height: 32px;
          }
        }
      }

    }

    &--default {
      .select2-selection--single .select2-selection__clear {
        font-size: 28px;
        font-weight: 300;
        height: 30px;
        margin: -1px 0 0;
      }

      .select2-search--dropdown {
        position: relative;
        padding: 5px 10px 5px 36px;
        border-bottom: 1px solid rgba(black, .1);

        &:before {
          @extend .fa;
          @extend .fa-search;

          font-size: 12px;
          color: $gray;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }

        .select2-search__field {
          border: none;
          padding: 5px 0;
        }
      }

      .select2-results__option {
        &[aria-selected="true"] {
          background-color: transparent;
          color: $font-color;

          &:before {
            @extend .fa;
            @extend .fa-check;

            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            color: #7f7f7f;
          }
        }

        .select2-results__option {
          padding-left: 32px;
        }
      }

      .select2-results__message {
        font-style: italic;
        text-align: center;
        color: $gray;
      }

      .select2-results__group {
        font-size: 11px;
        padding: 16px 8px 4px 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  &-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 1px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  &-results {
    .select2-results__option {
      font-size: 13px;
      position: relative;
      padding: 8px 16px 8px 32px;
      line-height: 1.3;

      &--highlighted {
        &[aria-selected],
        &[aria-selected="true"] {
          background-color: $smoke;
          color: $font-color;
        }
      }

      &[aria-selected=true] {
        display: none;
      }
    }
  }

  &-search--dropdown {
    .select2-search__field {
      &:focus {
        outline: none;
      }
    }
  }

  &-hidden-accessible {
    top: 0;
  }
}