$vs-component-placeholder-color: #999;
$vs-border-color: rgba(0, 0, 0, 0.1);
$vs-component-line-height: 1.75;
$vs-dropdown-z-index: 9999;


@import 'node_modules/vue-select/src/scss/vue-select.scss';

.v-select {
  background: $white;

  .vs__dropdown-toggle {
    height: 100%;
  }

  .vs__search,
  .vs__search:focus {
    border: none;
  }

  &:not(.vs--single) {
    .vs__selected {
      border-radius: 2px;
      color: white;
      background: $blue;
      border: none;
    }

    .vs__open-indicator {
      display: none;
    }

    .vs__deselect {
      fill: white;
    }
  }
}


