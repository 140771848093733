.popover {
  padding: 1rem 1.25rem;
  background: white;
  border: 1px solid rgba(black, 0.1);
  box-shadow: 0 2px 4px rgba(black, 0.1);
  border-radius: 4px;

  &-content {
    font-size: 13px;
    font-weight: 300;
    padding: 0;
  }

  &-title {
    margin: 0 0 0.25rem;
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
  }

  .arrow {
    border-width: 7px;
  }

  &.top .arrow {
    bottom: -7px;
    margin-left: -7px;
    border-top-color: rgba(black, 0.1);
  }

  &.bottom .arrow {
    top: -7px;
    margin-left: -7px;
    border-bottom-color: rgba(black, 0.1);
  }

  &.left .arrow {
    right: -7px;
    margin-top: -7px;
    border-left-color: rgba(black, 0.1);
  }

  &.right .arrow {
    left: -7px;
    margin-bottom: -7px;
    border-right-color: rgba(black, 0.1);
  }

  p {
    margin: 0;
  }

  ul, ol {
    padding: 0 0 0 1rem;
    margin: 0;

    li {
      margin: 0 0 0.25rem;
    }
  }
}
