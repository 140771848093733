$bg: #383e4b;
$light-bg: #f5f5f5;
$font-color: #383e4b;
$blue: #42a5f5;
$light-blue: #67c2ef;
$green: #27ae60;
$red: #e74c3c;
$white: #ffffff;
$gray: #7f7f7f;
$light-gray: #d3d3d3;
$smoke: #ececec;
$blue-smoke: #ebf7ff;
$orange: #fc7f48;

$link-color: #1e8fc6;
$link-hover-color: #36a9e1;

$warning-color: #fabb3d;
$success-color: #1bb933;
$border-color: rgba(black, .1);

$dove-gray: #666666;
$dusty-gray: #999999;
$mine-shaft: #333333;

$transperent_b_3: rgba(0, 0, 0, 0.30);
$transperent_b_6: rgba(0, 0, 0, 0.50);

$transfast: ease-in-out 0.15s;

$default-font: sans-serif;

// Navbar

$navbar-height: 60px;

// Sidebar

$sidebar-width: 180px;
$sidebar-width-min: 100px;

// Skills map

$skills-nav-width: 260px;

$mobile-nav-width: 240px;

// Device width breakpoints

$low-mobile: 320px;
$mobile: 480px;
$mobile-down: max-width 480px;
$phablet: 620px;
$phablet-down: max-width 620px;
$tablet: 768px;
$tablet-down: max-width ($tablet);
$tablet-portrait: $tablet (orientation portrait);
$desktop: 1024px;
$desktop-down: max-width 1024px;
$desktop-min: 1025px;
$desktop-hd: 1280px;
$desktop-uxga: 1600px;
$desktop-fhd: 1920px;
$desktop-qhd: 2560px;
