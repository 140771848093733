@charset "utf-8";

@import "../../../../../node_modules/susy/sass/susy";
@import "../../../../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "mixins";
@import "vars";
@import "animations";
@import "sprites";

// Fonts
@import "../../../../../node_modules/font-awesome/scss/font-awesome";
@import "fonts/font-awesome-alias";

// Vendor
@import "../vendor/css/bootstrap.css";
@import "../vendor/css/style.css";

// Drag'n'drop
@import "../vendor/css/dragula.min.css";

@import "../vendor/css/jquery.daterangepicker.min.css";

// Third party npm modules
@import '../../../../../node_modules/vue-good-table/dist/vue-good-table.css';
@import '../../../../../node_modules/vue2-datepicker/index.css';
@import "../../../../../node_modules/daterangepicker/daterangepicker";
@import '~vuejs-noty/dist/vuejs-noty.css';

@import "layout/layout";
@import "blocks/blocks";

// Vendor overrides
@import "vendoroverrides/misc";
@import "vendoroverrides/chosen";
@import "vendoroverrides/select2";
@import "vendoroverrides/jstree";
@import "vendoroverrides/datatables";
@import "vendoroverrides/slickgrid";
@import "vendoroverrides/google-charts";
@import "vendoroverrides/bootstrap/index";
@import "vendoroverrides/noty";
@import "vendoroverrides/noty_theme_light";
@import "vendoroverrides/vue_select";

// Job title
@import "job_title/job-titles";

// Reports
@import "reports/reports";

// Hire Employee
@import "hire_employee/hire-employee";

// TimeOff
@import "timeoff/list";

// Custom field
@import "custom_field/custom_fields";

// Employee Notifications Settings
@import "employee/employee_settings";

// Employee Notifications Settings
@import "employee/employee_settings";

// Errors
@import "errors/errors";

//Allocation
@import "allocation/projects";

//policies
@import "policies/policies";
