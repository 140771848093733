.add-vacation-item {
  display: inline-block;
  max-width: 600px;
}

.required-field {
    color: $red;
}

.optional-field {
    color: $gray;
}

.approvers-title {
  padding: .5em 0;
}

.days-count {
  margin: .5em 0 0 0;

  .calendardays-count,
  .workdays-count,
  .holidays-count {
      padding-right: 1em;
      display: inline-block;
  }
}

.days-count-text {
  font-weight: bold;
}

.approvers-multiselect {
    .select2 {
        width: 100% !important; // '!' is here to override the 'width: 100px' - base style of select2 element

        &-search__field, &-search {
            width: 100% !important; // '!' is here to override the 'width: 100px' - base style of select2 placeholder element
        }

    }
}

.field-error {
    display: none;
    padding-top: 0.5rem;
}

.calendar-input-period {
  padding-left: 0;

  input {
    width: 100%;
  }
}

.load-gif {
    width: 2em;
}

.label-loader {
    color: $blue;
}

.required-error {
    text-indent: -0.75em;
}

.end-date-title,
.doctor-appointment-date-title {
    padding-top: 0;
    margin-bottom: 0;
}

.approvers-tooltip-icon {
    color: lighten($gray, 25%);
}