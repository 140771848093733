.dropdown {
  &-menu {
    min-width: 200px;
    text-align: left;
    padding: 5px 0;
    margin: 2px 0 0;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 1px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    > li {
      > a {
        position: relative;
        margin: 0;
        padding: 8px 20px 8px 40px;

        &:hover,
        &:focus {
          color: $font-color;
          background: $smoke;
        }

        [class^="icon-"],
        [class*="fa-"] {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          color: $gray;
        }
      }
    }

    &__separator {
      height: 1px;
      width: 100%;
      margin: 5px 0;
      padding: 0;
      background: rgba(0,0,0,.15);
    }

    &-right {
      right: 0;
      left: auto;
    }

    &-left {
      left: 0;
      right: auto;
    }
  }
}
